import React, { Component } from "react";
import Store from "../../../Global/Store";
import { AppBreadCrumb, Menu } from "../../../Models/Menu";
import { AppMenuConfig } from "../AppMenus";
import Toolbar from "../Toolbar/Toolbar";
import { MainPageHTML } from "./MainPageHTML";


export enum TopSideEnum {
    TopBar,
    TopSideBar,
    TopSidebar
}
export interface IMainPageProps {
    pageName: string;
    topEnum: TopSideEnum;
    breadCrumbs?: {
        title: string;
        link: string
    }[];
    myMenus?: Menu[];
    module?: string,
    groupName?: string,
    detail?: boolean,
    page?: string
    content: JSX.Element;
    showFooter: boolean;
    showHeader: boolean;
}
export interface PageState {
    topBarRef: any;
    sidebarFold: boolean;
    pinned: boolean;
    breadCrumbs: AppBreadCrumb[];
    menus: Menu[];
}
export default class MainPage extends Component<IMainPageProps, PageState>{
    store = new Store();
    constructor(props: IMainPageProps) {
        super(props);
        const sidebarState = this.store.get("sidebarState");
        let sidebarFold = false;

        /** Always fold on mobile */
        if (window.innerWidth <= 767) {
            sidebarFold = true;
        } else {
            if (sidebarState) {
                sidebarFold = sidebarState === 'fold' ? true : false;
            }
        }
        const appMenusBreadCrumb = AppMenuConfig.getAppMenuConfig
            (
                props.module ? props.module : "",
                props.groupName ? props.groupName : props.pageName,
                props.page ? props.page : props.pageName
            )
        this.state = {
            topBarRef: React.createRef(),
            sidebarFold: sidebarFold,
            pinned: false,
            breadCrumbs: appMenusBreadCrumb.breadCrumbs,
            menus: appMenusBreadCrumb.menus
        }
        this.collapseSidebar = this.collapseSidebar.bind(this);
        this.expandSidebar = this.expandSidebar.bind(this);
        this.pinSideBar = this.pinSideBar.bind(this);
        this.toggleSidebar = this.toggleSidebar.bind(this);
    }

    componentDidUpdate(_: IMainPageProps, prevState: PageState) {
        if (prevState.sidebarFold !== this.state.sidebarFold) {
            const sidebarState = this.state.sidebarFold ? "fold" : "wide";
            this.store.set("sidebarState", sidebarState);
        }
    }

    toggleNotif(status: boolean, isNotifSuccess: boolean = false, notifBody: string = "", notifHeader: string = "") {
        (this.state.topBarRef.current as Toolbar).toggleNotif(status, isNotifSuccess, notifBody, notifHeader)
    }

    collapseSidebar() {
        if (!this.state.pinned) {
            this.setState({ sidebarFold: true });
        }
    }

    expandSidebar() {
        if (!this.state.pinned) {
            this.setState({ sidebarFold: false });
        }
    }

    toggleSidebar() {
        this.setState(prevState => ({
            sidebarFold: !prevState.sidebarFold
        }))
    }

    pinSideBar() {
        this.setState(prevState => ({
            pinned: !prevState.pinned
        }));
    }

    render() {
        return MainPageHTML(this);
    }

}
import "./TopSidebar.css";
import { Icon, Image, Popup } from "semantic-ui-react";
import AppDiv, { AppIcon, AppImg } from "../../../Global/Styles";
import TopSidebar from "./TopSidebar";
import { Link } from "react-router-dom";
import Store, { AppKey } from "../../../Global/Store";
import { UserRole } from "../../../Models/UserRole";
import { RoleModule } from "../../../Models/RoleModule";
import { RoleHelper } from "../RoleHelper";
import { Fragment } from "react";
import { Util } from "../../../Global/Util";
export const TopSidebarHTML = (pg: TopSidebar) => {
  // const myHtml = pg.props.myHtml;
  // const myMenus = pg.props.m;
  const { mainPage, menu } = pg.props;
  const selectedModule = new Store().get(AppKey.SELECTED_MODULE_MENU);
  const userRole = new Store().get(AppKey.USER).activeUser.parentRole as UserRole;
  const module = userRole.permissions.find((p) => p.name == selectedModule?.module);
  const moduleMenu = module?.routes.find((r) => r.name === selectedModule.groupName);

  return (
    //;md:84vh!important;sl:84vh;lg:75vh!important
    <AppDiv
      className=""
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        alignItems: "center",
        paddingTop: "4rem",
      }}
    >
      <AppIcon
        id="toggle"
        style={{
          position: "absolute",
          bottom: "2rem"
        }}
        floated="right"
        onClick={pg.props.mainPage.toggleSidebar}
        className={`sidebar-hide ${mainPage.state.sidebarFold && "fold"}`}
        name={`angle double ${mainPage.state.sidebarFold ? "right" : "left"}`}
        size="large"
        cursor="pointer"
      />
      {menu.map((m, k) => (
        <Fragment key={k}>
          {m?.disabled ? (
            <AppDiv
              className="sidebar-item"
              style={{ backgroundColor: "transparent", color: "#1A202C" }}
              display={
                moduleMenu?.pages.find((m) => m.name == m.name)
                  ? "flex !important"
                  : "none!important"
              }
            >
              <AppImg
                cursor="pointer"
                className="sideBar-img"
                width
                src={m.icon ? `/images/${m.icon}` : "/images/side_stock.svg"}
              />
              <AppDiv
                cursor="pointer"
                margin_left="1rem"
                className="sidebar-text"
                display="inline"
                font_size="17px"
                font_weight="600"
              >
                {!mainPage.state.sidebarFold && m.name}
              </AppDiv>
            </AppDiv>
          ) : mainPage.state.sidebarFold ? (
            <Popup
              content={m.name}
              position="right center"
              trigger={
                <AppDiv
                  className="sidebar-item"
                  style={{
                    backgroundColor: m.active ? "#099AD9" : "transparent",
                    color: m.active ? "#1A202C" : "#FFFFFF",
                    // padding: "10px",
                    // borderRadius: "12px",
                  }}
                  display={
                    moduleMenu?.pages.find((m) => m.name == m.name)
                      ? "flex !important"
                      : "none!important"
                  }
                >
                  <Link id={m.id} to={m.link}>
                    <AppDiv
                      font_size="14px"
                      className={
                        m?.disabled ? "" : m.active === true ? "" : ""
                      }
                    >
                      <AppImg
                        cursor="pointer"
                        className={`sideBar-img ${m.active && "active"}`}
                        width
                        src={m.icon ? `/images/${m.icon}` : "/images/side_stock.svg"}
                      />
                      {!mainPage.state.sidebarFold && (
                        <AppDiv
                          cursor="pointer"
                          margin_left="1rem"
                          className={`sidebar-text ${m.active && "active"}`}
                          display="inline"
                          text_color={m.active ? "#FFFFFF" : "#1A202C"}
                        >
                          {m.name}
                        </AppDiv>
                      )}
                    </AppDiv>
                    {/* {!mainPage.state.sidebarFold && m.active === true ? <Image style={{width:"70px",height:"115px"}} className='img' src='/images/left-triangle.png' size='tiny' centered /> : null} */}
                  </Link>
                </AppDiv>
              }
            />
          ) : (
            <AppDiv
              className="sidebar-item"
              style={{
                backgroundColor: m.active ? "#099AD9" : "transparent",
                color: m.active ? "#1A202C" : "#FFFFFF",
                width: "90%",
                padding: "1rem",
                borderRadius: "12px",
              }}
              display={
                moduleMenu?.pages.find((m) => m.name == m.name)
                  ? "flex !important"
                  : "none!important"
              }
            >
              <Link id={m.id} to={m.link}>
                <AppDiv
                  font_size="14px"
                  className={
                    m?.disabled ? "" : m.active === true ? "" : ""
                  }
                >
                  <AppImg
                    cursor="pointer"
                    className={`sideBar-img ${m.active && "active"}`}
                    width
                    src={m.icon ? `/images/${m.icon}` : "/images/side_stock.svg"}
                  />
                  {!mainPage.state.sidebarFold && (
                    <AppDiv
                      cursor="pointer"
                      margin_left="1rem"
                      className={`sidebar-text ${m.active && "active"}`}
                      display="inline"
                      text_color={m.active ? "#FFFFFF" : "#1A202C"}
                    >
                      {m.name}
                    </AppDiv>
                  )}
                </AppDiv>
                {/* {!mainPage.state.sidebarFold && m.active === true ? <Image style={{width:"70px",height:"115px"}} className='img' src='/images/left-triangle.png' size='tiny' centered /> : null} */}
              </Link>
            </AppDiv>
          )}
        </Fragment>
      ))}
      {/* {!mainPage.state.sidebarFold && (
                <AppDiv className="mt-5">
                    <Image  src='/images/sidebar-img.png' size='medium' centered />
                </AppDiv>
            )} */}
      {/* <AppDiv className='mb-auto'>
                <AppIcon id="toggle" floated="right" onClick={pg.props.mainPage.toggleSidebar} inverted={!mainPage.state.sidebarFold} className="sidebar-hide"
                    name={`angle double ${mainPage.state.sidebarFold ? "right" : "left"}`} size="large" cursor="pointer" />
            </AppDiv> */}
    </AppDiv>
  );
};

import { Component } from "react";
import { Md5Hash } from "../../../Global/HashString";
import LoginHTML from "./LoginHTML";
import Store, { AppKey } from '../../../Global/Store';
import { AuthUser } from "../../../Models/AuthUser";
import { ErrorResponse } from "../../../Models/ErrorResponse";
import { AuthService } from "../../../HttpServices/Auth/AuthService";
import history from '../../Components/BrowserHistory'
import React from "react";
import MainPage from "../../Components/MainPage/MainPage";
// import { useHistory } from 'react-router-dom';

interface Props {
  // history: any
}
export interface PageState {
  user: AuthUser;
  loading: boolean;
  mainPageRef: any;
  breadCrumbs: {
    title: string;
    link: string
  }[];

}

export default class Login extends Component<Props, PageState> {
  authService = new AuthService();
  abortController = new AbortController();
  store = new Store();
  constructor(props: Props) {
    super(props);
    this.state = {
      mainPageRef: React.createRef(),
      user: new AuthUser(),
      loading: false,
      breadCrumbs: []
    };
  }
  handleChange = (event: any, na: any = {}) => {
    var name = event.target.name ? event.target.name : na.name;
    const value = event.target?.value ? event.target.value : na.value
    this.setState(prevState => ({
      ...prevState,
      user: {
        ...prevState.user,
        [name]: value
      }
    }));
  }
  handleSubmit = (e: any) => {
    e.preventDefault();
    this.userLogin();
  };
  async userLogin() {
    this.setState({ loading: true });
    const loginUser = this.state.user as AuthUser;
    var loginResponse = await this.authService.signIn({ key: loginUser.key, value: Md5Hash(loginUser.value) });
    if (loginResponse instanceof ErrorResponse) {
      // alert('Email Address or password is incorrect');
      (this.state.mainPageRef.current as MainPage).toggleNotif(true, false, loginResponse.message);
      this.setState({ loading: false });
    } else {
      this.store.set(AppKey.USER, loginResponse as AuthUser);
      history.push('/home')
      //  this.abortController.abort();
    }
    this.setState({ loading: false });
  }
  render() {
    return LoginHTML(this);
  }

}

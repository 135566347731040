import axios from "axios";
import { EntityState } from "../../Models/EntityState";
import { ErrorResponse } from "../../Models/ErrorResponse";
import { PagedResult } from "../../Models/PagedResult";
import { AuthUser } from "../../Models/AuthUser";
import { UserRole } from "../../Models/UserRole";
import { baseURL, HttpRequest } from "../HttpRequest";
import { HeaderKeyValue } from "../HeaderKeyValue";
import { License } from "../../Models/License";
import { Contract } from "../../Models/Contract";
import { TenantRequest } from "../../Models/TenantRequest";
import { Subscription } from "../../Models/Subscription";
import { RoleModule } from "../../Models/RoleModule";
import { Tenant } from "../../Models/Tenant";
import { Util } from "../../Global/Util";

export class AuthService {
  /**
   *
   */
  httpRequest: HttpRequest | null = null;
  constructor() {
    this.httpRequest = new HttpRequest(
      axios.create({
        baseURL: baseURL + "/auth",
        timeout: 60000,
      }),
      []
    );
  }
  async getRoles(search?: string) {
    const response = await this.httpRequest?.get<
      PagedResult<UserRole[]> | UserRole | ErrorResponse
    >(search ? `/role/get/${search}` : `/role/getroles/${undefined}`);
    return response;
  }

  async getUsers(search?: string, roleId: string = "", last: string = "") {
    // let t = window.location.href.split('/')[2]
    // t = t.includes("localhost") ? "verbena.applauseerp.com" : t;
    const response = await this.httpRequest?.get<
      PagedResult<AuthUser[]> | ErrorResponse
    >(
      search && !roleId
        ? `/user/getbyrole/${search}/undefined/20`
        : `/user/getbyrole/${roleId}/${search}/${last}`
    );
    return response;
  }
  // GetByRole/{roleID}/{search?}/{last?}")
  async checkIfUserExistinTenant(search?: string) {
    // let t = window.location.href.split('/')[2]
    // t = t.includes("localhost") ? "verbena.applauseerp.com" : t;
    const response = await this.httpRequest?.get<
      PagedResult<AuthUser[]> | ErrorResponse
    >(`/user/isempty`, [
      new HeaderKeyValue("Authorization", "Bearer Euc25d4KzUiXObptKjNrJg=="),
      new HeaderKeyValue("Tenant", Util.getBaseUrl()),
    ]);
    return response;
  }
  async saveUser(data: AuthUser) {
    (data as any).parentRole = null;
    const response = await this.httpRequest?.post<AuthUser | ErrorResponse>(
      data.state === EntityState.New
        ? `/user/create/${data.password}`
        : `/user/edit`,
      data
    );
    return response;
  }
  async saveAllPermissions(data: RoleModule[]) {
    const response = await this.httpRequest?.post<any | ErrorResponse>(
      `/permission`,
      data
    );
    return response;
  }
  async saveUserFirstTime(data: AuthUser) {
    const response = await this.httpRequest?.post<AuthUser | ErrorResponse>(
      `/user/createfirst/${data.password}`,
      data,
      [
        new HeaderKeyValue("Authorization", "Bearer Euc25d4KzUiXObptKjNrJg=="),
        new HeaderKeyValue("Tenant", Util.getBaseUrl()),
      ]
    );
    return response;
  }
  async signIn(data: any) {
    const response = await this.httpRequest?.post<AuthUser | ErrorResponse>(
      `/user/validate`,
      data,
      [
        new HeaderKeyValue("Authorization", "Bearer Euc25d4KzUiXObptKjNrJg=="),
        new HeaderKeyValue("Tenant", Util.getBaseUrl()),
      ]
    );
    return response;
  }
  async changePassword(user: AuthUser, newPassword: string) {
    const data = {
      user,
      newPassword,
    };
    const response = await this.httpRequest?.post<any | ErrorResponse>(
      `/user/changepassword`,
      data
    );
    return response;
  }

  async saveRoles(data: UserRole[]) {
    const json = JSON.stringify(data[0]);
    Util.log(data[0]);
    const response = await this.httpRequest?.post<UserRole[] | ErrorResponse>(
      "role",
      data[0]
    );
    // if (response  instanceof ErrorResponse==false) {
    //   (response as UserRole[]).forEach(r => {
    //     r.state = EntityState.Unchanged;
    //   });
    // }
    return response;
  }
  async getLicense(qry: string) {
    const response = await this.httpRequest?.get<License | ErrorResponse>(
      `/license/${qry}`
    );
    return response;
  }
  async getLicenses(qry: string | undefined = "008") {
    const response = await this.httpRequest?.get<
      PagedResult<License[]> | ErrorResponse
    >(`/licenses/${qry}`);
    return response;
  }
  async getLicenseManager() {
    const response = await this.httpRequest?.get<
      PagedResult<TenantRequest> | ErrorResponse
    >(`/licensemanager`);
    return response;
  }
  async saveLicense(data: License[]) {
    const response = await this.httpRequest?.post<
      PagedResult<License> | ErrorResponse
    >(`/license/save`, data);
    return response;
  }
  async requestPasswordChange(mail: string) {
    const response = await this.httpRequest?.get<any | ErrorResponse>(
      `/user/requestpasswordchange/${mail}`
    );
    return response;
  }
  async validateOTP(mail: string, otp: string) {
    const response = await this.httpRequest?.get<any | ErrorResponse>(
      `/user/validateotp/${mail}/${otp}`
    );
    return response;
  }
  async saveContract(contract: Contract, subscription: Subscription) {
    var data = {
      TenantContract: contract,
      TenantSubscription: subscription,
    };
    const response = await this.httpRequest?.post<any | ErrorResponse>(
      `/contract/save`,
      data
    );
    return response;
  }
  async getContract(code: string = "") {
    const response = await this.httpRequest?.get<Contract | ErrorResponse>(
      `/contract/${code}`
    );
    return response;
  }
  async getSubscriptions(code: string = "") {
    const response = await this.httpRequest?.get<
      PagedResult<Subscription[]> | ErrorResponse
    >(`/Subscription/Get/${code}/undefined`);
    return response;
  }
  async getActiveSubscription() {
    const response = await this.httpRequest?.get<Subscription | ErrorResponse>(
      `/Subscription/Active`
    );
    return response;
  }
  async editSubscription(subscription: Subscription) {
    const response = await this.httpRequest?.post<any | ErrorResponse>(
      `/subscription/save`,
      subscription
    );
    return response;
  }
  async activateSubscription(ownerTenant: string = "") {
    const response = await this.httpRequest?.post<any | ErrorResponse>(
      `/subscription/activate/${ownerTenant}`
    );
    return response;
  }
  async renewSubscription(ownerTenant: string = "") {
    const response = await this.httpRequest?.post<any | ErrorResponse>(
      `/subscription/Renew/${ownerTenant}`
    );
    return response;
  }
  async SaveTenantRequest(data: TenantRequest[]) {
    const response = await this.httpRequest?.post<any[] | ErrorResponse>(
      `/tenantrequest/save`,
      data
    );
    return response;
  }
  async getTenantRequest(code: string = "") {
    const response = await this.httpRequest?.get<
      PagedResult<TenantRequest[]> | ErrorResponse
    >(`/tenantrequest/get/${code}`);
    return response;
  }

  async getActiveTenant(last: string = "2022-01-01") {
    const response = await this.httpRequest?.get<Tenant | ErrorResponse>(
      `/tenantrequest/GetActive/${last}`
    );
    return response;
  }

  async getTenantRequestByURL() {
    // let t = window.location.href.split('/')[2]
    // let url = t.includes("localhost") ? "verbena.applauseerp.com" : t;
    // url = 'reed.applauseerp.com'
    const response = await this.httpRequest?.get<
      PagedResult<TenantRequest[]> | ErrorResponse
    >(`/tenantrequest/GetRequest/${Util.getBaseUrl()}`);
    return response;
  }

  async getTemporaryTenant() {
    const response = await this.httpRequest?.get<Tenant | ErrorResponse>(
      `/tenantrequest/get/info@pfs.com`
    );
    return response;
  }
}

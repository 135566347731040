export enum OperationLink {
  JournalApproval = "/journal",
  StockTransferApproval = "/stock-transfer",
  VendorInvoiceApproval = "/vendor-invoice",
  CustomerInvoiceApproval = "/customer-invoice",
  VendorPaymentScheduleApproval = "/vendor-payment-schedule",
  CustomerPaymentScheduleApproval = "/customer-payment-schedule",
  /** Added as a default */
  None = ""
}

import {
  AppButton,
  AppForm,
  AppFormInput,
  AppGrid,
  AppP,
} from "../../../Global/Styles";
import "./Login.css";

import Login from "./Login";
import { AuthUser } from "../../../Models/AuthUser";
import { Link } from "react-router-dom";
import MainPage, { TopSideEnum } from "../../Components/MainPage/MainPage";

const LoginHTML = (pg: Login) => {
  const user = pg.state.user as AuthUser;
  const content = (): JSX.Element => {
    return (
      <>
      <div className="login-page">
        <div className="login-form-section">
          <AppGrid.Row className="mx-auto w-75">
            <AppP
              font_weight="bold"
              font_size="30px"
              text_color="#1A202C"
              margin_bottom="20px!important"
            >
              Login
            </AppP>
            <AppP text_color="#1A202C" margin_bottom="50px!important">
              Please enter your credentials to proceed
            </AppP>
            <AppForm>
              <AppFormInput
                required
                size="big"
                fluid
                id="email"
                label="Email Address"
                value={user.key}
                onChange={pg.handleChange}
                placeholder="Enter email address"
                margin_bottom="50px!important"
                name="key"
              />
              <AppFormInput
                required
                size="big"
                fluid
                id="password"
                type="password"
                label="Password"
                value={user.value}
                onChange={pg.handleChange}
                placeholder="Enter password"
                name="value"
                margin_bottom="20px!important"
              />
              <AppForm.Group inline>
                <AppForm.Checkbox label="Remember Me" />
                <Link
                  style={{
                    borderBottom: "1px solid #4584c5",
                    marginLeft: "auto",
                  }}
                  to="/forgot-password"
                >
                  Forgot Password?
                </Link>
              </AppForm.Group>
              <AppButton
                id="signin"
                onClick={() => pg.userLogin()}
                margin_top="50px!important"
                size="huge"
                className="submitBtn text-center"
                loading={pg.state.loading}
                fluid
              >
                Sign In
              </AppButton>
            </AppForm>
          </AppGrid.Row>
        </div>


        <div className="login-img-section">
            <AppGrid.Row className="">
              <img alt="" src="/images/login-img.png" />
            </AppGrid.Row>
        </div>
      </div>
      {/* <AppGrid height="100vh">
        <AppGrid.Row columns={2}>
          <AppGrid.Column verticalAlign="middle">
            <AppGrid.Row className="mx-auto w-75">
              <AppP
                font_weight="bold"
                font_size="30px"
                text_color="#1A202C"
                margin_bottom="20px!important"
              >
                Login
              </AppP>
              <AppP text_color="#1A202C" margin_bottom="50px!important">
                Please enter your credentials to proceed
              </AppP>
              <AppForm>
                <AppFormInput
                  required
                  size="big"
                  fluid
                  id="email"
                  label="Email Address"
                  value={user.key}
                  onChange={pg.handleChange}
                  placeholder="Enter email address"
                  margin_bottom="50px!important"
                  name="key"
                />
                <AppFormInput
                  required
                  size="big"
                  fluid
                  id="password"
                  type="password"
                  label="Password"
                  value={user.value}
                  onChange={pg.handleChange}
                  placeholder="Enter password"
                  name="value"
                  margin_bottom="20px!important"
                />
                <AppForm.Group inline>
                  <AppForm.Checkbox label="Remember Me" />
                  <Link
                    style={{
                      borderBottom: "1px solid #4584c5",
                      marginLeft: "auto",
                    }}
                    to="/forgot-password"
                  >
                    Forgot Password?
                  </Link>
                </AppForm.Group>
                <AppButton
                  id="signin"
                  onClick={() => pg.userLogin()}
                  margin_top="50px!important"
                  size="huge"
                  className="submitBtn text-center"
                  loading={pg.state.loading}
                  fluid
                >
                  Sign In
                </AppButton>
              </AppForm>
            </AppGrid.Row>
          </AppGrid.Column>
          <AppGrid.Column style={{ background: "#a8e5ff" }}>
            <AppGrid.Row className="pwdImage">
              <img alt="" src="/images/login-img.png" />
            </AppGrid.Row>
          </AppGrid.Column>
        </AppGrid.Row>
      </AppGrid> */}
      </>
    );
  };
  return (
    <MainPage
      showHeader={false}
      showFooter={false}
      ref={pg.state.mainPageRef}
      pageName="Login"
      topEnum={TopSideEnum.TopBar}
      breadCrumbs={pg.state.breadCrumbs}
      content={content()}
    />
  );
};
export default LoginHTML;

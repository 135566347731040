import "./App.css";
import * as React from "react";
import { Suspense } from "react";
import { Route, Router, Switch } from "react-router-dom";
import RouteGuard from "../../Components/RouteGuard";
import Store, { AppKey } from "../../../Global/Store";
import history from "../../Components/BrowserHistory";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "antd/dist/antd.css";
import AppDiv from "../../../Global/Styles";
import { Dimmer, Loader } from "semantic-ui-react";
import { AuthService } from "../../../HttpServices/Auth/AuthService";
import { ErrorResponse } from "../../../Models/ErrorResponse";
import UserPage from "../UserPage/UserPage";
import { OperationLink } from "../../../Models/OperationLink";
import { Md5Hash } from "../../../Global/HashString";

export interface IAppProps {
  example?: string;
}

const PaymentDescription = React.lazy(
  () => import("../Payroll/PaymentDescription/PaymentDescription")
);

const PayrollList = React.lazy(
  () => import("../Payroll/PayrollList/PayrollList")
);
const Payroll = React.lazy(() => import("../Payroll/Payroll/Payroll"));

const Staff = React.lazy(() => import("../Payroll/Staff/Staff"));
const Department = React.lazy(() => import("../Payroll/Department/Department"));
const StaffUnit = React.lazy(() => import("../Payroll/StaffUnit/StaffUnit"));

const Allowance = React.lazy(() => import("../Payroll/Allowance/Allowance"));
const StaffAllowance = React.lazy(
  () => import("../Payroll/StaffAllowance/StaffAllowance")
);
const Deduction = React.lazy(() => import("../Payroll/Deduction/Deduction"));
const StaffDeduction = React.lazy(
  () => import("../Payroll/StaffDeduction/StaffDeduction")
);
const GradeLevel = React.lazy(() => import("../Payroll/GradeLevel/GradeLevel"));
const SalaryScale = React.lazy(
  () => import("../Payroll/SalaryScale/SalaryScale")
);
const Step = React.lazy(() => import("../Payroll/Step/Step"));

const AttributeSet = React.lazy(
  () => import("../GeneralLedger/AttributeSet/AttributeSet")
);
const Attributes = React.lazy(
  () => import("../GeneralLedger/Attributes/Attributes")
);
const RecurringDepreciation = React.lazy(
  () => import("../FixedAsset/RecurrentDepreciation/RecurrentDepreciation")
);
const ModuleConfig = React.lazy(
  () => import("../ModuleManagement/ModuleConfig/ModuleConfig")
);
const LedgerHeader = React.lazy(
  () => import("../ModuleManagement/LedgerHeader/LedgerHeader")
);
const Subsidiary = React.lazy(
  () => import("../ModuleManagement/Subsidiary/Subsidiary")
);
const Operations = React.lazy(
  () => import("../ModuleManagement/Operations/Operations")
);
const Tax = React.lazy(() => import("../Tax/Tax"));
const VendorStatement = React.lazy(
  () => import("../AccountPayable/VendorStatement/VendorStatement")
);
const CustomerStatement = React.lazy(
  () => import("../AccountReceivable/CustomerStatement/CustomerStatement")
);
const CreateCustomerPayment = React.lazy(
  () =>
    import("../AccountReceivable/CreateCustomerPayment/CreateCustomerPayment")
);
const CreateVendorPayment = React.lazy(
  () => import("../AccountPayable/CreateVendorPayment/CreateVendorPayment")
);
const VendorPayment = React.lazy(
  () => import("../AccountPayable/VendorPayment/VendorPayment")
);
const CustomerPayment = React.lazy(
  () => import("../AccountReceivable/CustomerPayment/CustomerPayment")
);
const VendorPaymentTerm = React.lazy(
  () => import("../AccountPayable/VendorPaymentTerm/VendorPaymentTerm")
);
const CustomerPaymentTerm = React.lazy(
  () => import("../AccountReceivable/CustomerPaymentTerm/CustomerPaymentTerm")
);
const InsuranceScheduleList = React.lazy(
  () => import("../FixedAsset/InsuranceScheduleList/InsuranceScheduleList")
);
const InsuranceSchedule = React.lazy(
  () => import("../FixedAsset/InsuranceSchedule/InsuranceSchedule")
);
const SplittingScheduleList = React.lazy(
  () => import("../FixedAsset/SplittingScheduleList/SplittingScheduleList")
);
const SplittingSchedule = React.lazy(
  () => import("../FixedAsset/SplittingSchedule/SplittingSchedule")
);
const InsuranceCompany = React.lazy(
  () => import("../FixedAsset/Insurance/Insurance")
);
// const ProductGroup = React.lazy(() => import("../ProductManagement/ProductGroup/ProductGroup"));
const ProductGroup = React.lazy(
  () => import("../InventoryManagement/ProductGroup/ProductGroup")
);
const Product = React.lazy(
  () => import("../InventoryManagement/Product/Product")
);
const CustomerInvoice = React.lazy(
  () => import("../AccountReceivable/CustomerInvoice/CustomerInvoice")
);
const CustomerInvoices = React.lazy(
  () => import("../AccountReceivable/CustomerInvoiceList/CustomerInvoiceList")
);
const CustomerInvoiceReversal = React.lazy(
  () =>
    import(
      "../AccountReceivable/CustomerInvoiceReversal/CustomerInvoiceReversal"
    )
);
const VendorInvoice = React.lazy(
  () => import("../AccountPayable/VendorInvoice/VendorInvoice")
);
const VendorInvoices = React.lazy(
  () => import("../AccountPayable/VendorInvoiceList/VendorInvoiceList")
);
const VendorInvoiceReversal = React.lazy(
  () => import("../AccountPayable/VendorInvoiceReversal/VendorInvoiceReversal")
);
const AssetStockList = React.lazy(
  () => import("../FixedAsset/AssetStockList/AssetStockList")
);
const AssetStock = React.lazy(
  () => import("../FixedAsset/AssetStock/AssetStock")
);
const RevaluationScheduleList = React.lazy(
  () => import("../FixedAsset/RevaluationScheduleList/RevaluationScheduleList")
);
const RevaluationSchedule = React.lazy(
  () => import("../FixedAsset/RevaluationSchedule/RevaluationSchedule")
);
const DisposalScheduleList = React.lazy(
  () => import("../FixedAsset/DisposalScheduleList/DisposalScheduleList")
);
const DisposalSchedule = React.lazy(
  () => import("../FixedAsset/DisposalSchedule/DisposalSchedule")
);
const DepreciationScheduleList = React.lazy(
  () =>
    import("../FixedAsset/DepreciationScheduleList/DepreciationScheduleList")
);
const DepreciationSchedule = React.lazy(
  () => import("../FixedAsset/DepreciationSchedule/DepreciationSchedule")
);
const MaintenanceScheduleList = React.lazy(
  () => import("../FixedAsset/MaintenanceScheduleList/MaintenanceScheduleList")
);
const MaintenanceSchedule = React.lazy(
  () => import("../FixedAsset/MaintenanceSchedule/MaintenanceSchedule")
);
const AssetItem = React.lazy(() => import("../FixedAsset/AssetItem/AssetItem"));
const AssetList = React.lazy(() => import("../FixedAsset/AssetList/AssetList"));
const AssetClass = React.lazy(
  () => import("../FixedAsset/AssetClass/AssetClass")
);
const SuccessPg = React.lazy(() => import("../Success/Success"));
// const Home = React.lazy(() => import("../Home/Home"));
const Login = React.lazy(() => import("../Login/Login"));
const NumberSequence = React.lazy(
  () => import("../NumberSequence/NumberSequence")
);
const NumberSequenceAdd = React.lazy(
  () => import("../NumberSequenceDetail/NumberSequenceDetail")
);
const TrialBalance = React.lazy(() => import("../TrialBalance/TrialBalance"));
const CurrencyExchange = React.lazy(
  () => import("../CurrencyList/CurrencyList")
);
const Rate = React.lazy(() => import("../ExchangeRate/ExchangeRate"));
// const NumberSequenceId = React.lazy(() => import("../NumberSequenceId/NumberSequenceId"));
const Home = React.lazy(() => import("../Home/Home"));
const CreateCompany = React.lazy(
  () => import("../CreateCompany/CreateCompany")
);
const ReportViewer = React.lazy(() => import("../ReportViewer/ReportViewer"));
const ReportDesigner = React.lazy(
  () => import("../ReportDesigner/ReportDesigner")
);
const ViewAll = React.lazy(() => import("../ViewAll/ViewAll"));
const CreateDomain = React.lazy(() => import("../CreateDomain/CreateDomain"));
const Subscription = React.lazy(() => import("../Subscription/Subscription"));
const Dimension = React.lazy(() => import("../Dimension/Dimension"));
const DimensionGroup = React.lazy(
  () => import("../DimensionGroup/DimensionGroup")
);
const PostingProfile = React.lazy(
  () => import("../PostingProfile/PostingProfile")
);
const ConsolidationMapping = React.lazy(
  () => import("../Admin/ConsolidationMapping/ConsolidationMapping")
);
const Consolidation = React.lazy(
  () => import("../Admin/Consolidation/Consolidation")
);
const AllocationRule = React.lazy(
  () => import("../AllocationRule/AllocationRule")
);
// const OrganisationStructure = React.lazy(() => import("../OrganisationStructure/OrganisationStructure"));
const PageNotFound = React.lazy(() => import("../PageNotFound/PageNotFound"));
const NewContract = React.lazy(() => import("../NewContract/NewContract"));
const CreateAdmin = React.lazy(() => import("../CreateAdmin/CreateAdmin"));
const ForgotPassword = React.lazy(
  () => import("../ForgotPassword/ForgotPassword")
);
const Verification = React.lazy(() => import("../Verification/Verification"));
const ChartOfAccounts = React.lazy(
  () => import("../../Components/TopSidebar/TopSidebar")
);
const AccountClass = React.lazy(() => import("../AccountClass/AccountClass"));
const AccountGroup = React.lazy(() => import("../AccountGroup/AccountGroup"));
const Accounts = React.lazy(() => import("../Accounts/Accounts"));
const JournalTemplate = React.lazy(
  () => import("../JournalTemplate/JournalTemplate")
);
const AdjustmentJournal = React.lazy(
  () => import("../JournalAdjustment/JournalAdjustment")
);
const AdjustmentJournalVoucher = React.lazy(
  () => import("../JournalAdjustmentVoucher/JournalAdjustmentVoucher")
);

const OpeningBalanceJournal = React.lazy(
  () => import("../OpeningBalanceJournal/OpeningBalanceJournal")
);
const OpeningBalanceJournals = React.lazy(
  () => import("../OpeningBalanceJournals/OpeningBalanceJournals")
);

const Journal = React.lazy(() => import("../Journal/Journal"));
const JournalVoucher = React.lazy(
  () => import("../JournalVoucher/JournalVoucher")
);
const JournalReversal = React.lazy(
  () => import("../JournalReversal/JournalReversal")
);
const RecurringJournalList = React.lazy(
  () => import("../RecurringJournalList/RecurringJournalList")
);
const RecurringJournal = React.lazy(
  () => import("../RecurringJournal/RecurringJournal")
);
const AccountingPeriod = React.lazy(
  () => import("../AccountingPeriod/AccountingPeriod")
);
const FiscalYear = React.lazy(() => import("../FiscalYear/FiscalYear"));
const CompanyConfig = React.lazy(
  () => import("../CompanyConfig/CompanyConfig")
);
const Sample = React.lazy(() => import("../Sample/Sample"));
const BankUI = React.lazy(() => import("../CashManagement/Bank/Bank"));
const BankGroupUI = React.lazy(
  () => import("../CashManagement/BankGroup/BankGroup")
);
const RoleUI = React.lazy(() => import("../Role/RoleRoute"));
// const ActiveUserUI = React.lazy(() => import("../ActiveUser/ActiveUser"));
const UserProfile = React.lazy(() => import("../UserProfile/UserProfile"));
const NewPassword = React.lazy(() => import("../NewPassword/NewPassword"));
const AuditTrail = React.lazy(() => import("../AuditTrail/AuditTrail"));
const OrgStructure = React.lazy(() => import("../Admin/OrgChart/OrgChart"));
const TenantForm = React.lazy(() => import("../Admin/TenantForm/TenantForm"));
const CompanyForm = React.lazy(
  () => import("../Admin/CompanyForm/CompanyForm")
);
const TransactionHistory = React.lazy(
  () => import("../TransactionHistory/TransactionHistory")
);
const TransactionGroup = React.lazy(
  () => import("../TransactionGroup/TransactionGroup")
);
const MessageSettings = React.lazy(
  () => import("../MessageSettings/MessageSettings")
);
const ServiceMonitor = React.lazy(
  () => import("../ServiceMonitor/ServiceMonitor")
);
const Licensing = React.lazy(() => import("../Licensing/Licensing"));
const LicenseManager = React.lazy(
  () => import("../LicenseManager/LicenseManager")
);
const ContractList = React.lazy(() => import("../ContractList/ContractList"));
const Contract = React.lazy(() => import("../Contract/Contract"));
const IntercompanyJournalTemplate = React.lazy(
  () =>
    import(
      "../GeneralLedger/IntercompanyJournalTemplate/IntercompanyJournalTemplate"
    )
);
const IntercompanyJournal = React.lazy(
  () => import("../GeneralLedger/IntercompanyJournal/IntercompanyJournal")
);
const IntercompanyJournalVoucher = React.lazy(
  () =>
    import(
      "../GeneralLedger/IntercompanyJournalVoucher/IntercompanyJournalVoucher"
    )
);
const Workflow = React.lazy(
  () => import("../WorkflowRoutes/Workflows/Workflows")
);
const WorkflowChart = React.lazy(
  () => import("../WorkflowRoutes/Workflows/WorkflowChart2/WorkflowChart2")
);
const Escalation = React.lazy(
  () => import("../WorkflowRoutes/Workflows/Escalate/Escalate")
);
const WorkflowTag = React.lazy(
  () => import("../WorkflowRoutes/WorkflowTags/WorkflowTags")
);
const WorkflowActor = React.lazy(
  () => import("../WorkflowRoutes/WorkflowActors/WorkflowActors")
);
const WorkflowTask = React.lazy(
  () => import("../WorkflowRoutes/WorkflowTasks/WorkflowTasks")
);
const Customer = React.lazy(
  () => import("../AccountReceivable/Customer/Customer")
);
const CustomerGroup = React.lazy(
  () => import("../AccountReceivable/CustomerGroup/CustomerGroup")
);
const Vendor = React.lazy(() => import("../AccountPayable/Vendor/Vendor"));
const VendorGroup = React.lazy(
  () => import("../AccountPayable/VendorGroup/VendorGroup")
);
const CustomerPaymentSchedule = React.lazy(
  () =>
    import(
      "../AccountReceivable/CustomerPaymentSchedule/CustomerPaymentSchedule"
    )
);
const CustomerPaymentSchedules = React.lazy(
  () =>
    import(
      "../AccountReceivable/CustomerPaymentScheduleList/CustomerPaymentScheduleList"
    )
);
const RecurringCustomerPayment = React.lazy(
  () =>
    import(
      "../AccountReceivable/RecurringCustomerPayment/RecurringCustomerPayment"
    )
);
const RecurringCustomerPayments = React.lazy(
  () =>
    import(
      "../AccountReceivable/RecurringCustomerPaymentList/RecurringCustomerPaymentList"
    )
);
const VendorPaymentSchedule = React.lazy(
  () => import("../AccountPayable/VendorPaymentSchedule/VendorPaymentSchedule")
);
const VendorPaymentSchedules = React.lazy(
  () =>
    import(
      "../AccountPayable/VendorPaymentScheduleList/VendorPaymentScheduleList"
    )
);
const RecurringVendorPayment = React.lazy(
  () =>
    import("../AccountPayable/RecurringVendorPayment/RecurringVendorPayment")
);
const RecurringVendorPayments = React.lazy(
  () =>
    import(
      "../AccountPayable/RecurringVendorPaymentList/RecurringVendorPaymentList"
    )
);
const BankReconciliation = React.lazy(
  () => import("../CashManagement/BankReconciliation/BankReconciliation")
);
const BankReconciliations = React.lazy(
  () => import("../CashManagement/BankReconciliationList/BankReconciliationList")
);
const FundTransfers = React.lazy(
  () => import("../CashManagement/FundTransferList/FundTransferList")
);
const FundTransfer = React.lazy(
  () => import("../CashManagement/FundTransfer/FundTransfer")
);
const CashflowForecasts = React.lazy(
  () => import("../CashManagement/CashflowForecastList/CashflowForecastList")
);
const CashflowForecast = React.lazy(
  () => import("../CashManagement/CashflowForecast/CashflowForecast")
);
const IdentityGroup = React.lazy(
  () => import("../GeneralLedger/IdentityGroup/IdentityGroup")
);
const Identity = React.lazy(() => import("../GeneralLedger/Identity/Identity"));
const Address = React.lazy(() => import("../GeneralLedger/Address/Address"));
const Site = React.lazy(() => import("../WarehouseManagement/Site/Site"));
const Warehouse = React.lazy(
  () => import("../WarehouseManagement/Warehouse/Warehouse")
);
const Location = React.lazy(
  () => import("../WarehouseManagement/Location/Location")
);
const Stock = React.lazy(() => import("../InventoryManagement/Stock/Stock"));
const StockMovement = React.lazy(
  () => import("../InventoryManagement/StockMovement/StockMovement")
);
const StockTransfers = React.lazy(
  () => import("../InventoryManagement/TransferList/TransferList")
);
const StockTransfer = React.lazy(
  () => import("../InventoryManagement/Transfer/Transfer")
);

const Dashboard = React.lazy(() => import("../Dashboard/Dashboard"));
export interface PageState {
  root?: any;
  loading: boolean;
  msg: string;
}
export default class App extends React.Component<IAppProps, PageState> {
  constructor(props: IAppProps) {
    super(props);
    // var t = Md5Hash('ja123');
    this.state = {
      loading: false,
      root: undefined,
      msg: "",
    };
  }
  async componentDidMount() {
    var hasActiveUser = new Store().get(AppKey.HasActiveUser);
    if (!hasActiveUser) {
      this.setState({ loading: true });
      var result = await new AuthService().checkIfUserExistinTenant();
      if (result instanceof ErrorResponse == false) {
        if (result == false) {
          new Store().set(AppKey.HasActiveUser, "1");
        } else {
          new Store().clearAll();
        }
      } else {
        this.setState({
          msg: "Unable to contact server, please contact the administrator",
        });
      }
      this.setState({ loading: false });
    }
  }

  public render() {
    const { loading, msg } = this.state;
    const auth = new Store().get(AppKey.USER);
    const isAuthenticated = auth ? true : false;
    const hasActiveUser = new Store().get(AppKey.HasActiveUser) ? true : false;
    return (
      <Suspense
        fallback={
          <Dimmer active={true} inverted>
            <Loader size="large">Initializing...</Loader>
          </Dimmer>
        }
      >
        {loading ? (
          <Dimmer active={true} inverted>
            <Loader size="large">Validating...</Loader>
          </Dimmer>
        ) : msg ? (
          <AppDiv>{msg}</AppDiv>
        ) : (
          <Router history={history}>
            <Switch>
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/payment-description"
                component={PaymentDescription}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/salary-schedules"
                component={PayrollList}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/salary-schedule"
                component={Payroll}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/staff-unit"
                component={StaffUnit}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/staff-department"
                component={Department}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/staff"
                component={Staff}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/salary-allowance"
                component={Allowance}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/staff-allowance"
                component={StaffAllowance}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/salary-deduction"
                component={Deduction}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/staff-deduction"
                component={StaffDeduction}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/grade-level"
                component={GradeLevel}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/salary-step"
                component={Step}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/salary-scale"
                component={SalaryScale}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/attribute-set"
                component={AttributeSet}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/attribute"
                component={Attributes}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/recurring-depreciation"
                component={RecurringDepreciation}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/module-config"
                component={ModuleConfig}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/ledger-header"
                component={LedgerHeader}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/subsidiary"
                component={Subsidiary}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/operation"
                component={Operations}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/tax"
                component={Tax}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/customer-statement"
                component={CustomerStatement}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/vendor-statement"
                component={VendorStatement}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/vendor-payment"
                component={CreateVendorPayment}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/customer-payment"
                component={CreateCustomerPayment}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/vendor-payments"
                component={VendorPayment}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/customer-payments"
                component={CustomerPayment}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/vendor-payment-term"
                component={VendorPaymentTerm}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/customer-payment-term"
                component={CustomerPaymentTerm}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/insurance-schedules"
                component={InsuranceScheduleList}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/insurance-schedule"
                component={InsuranceSchedule}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/splitting-schedules"
                component={SplittingScheduleList}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/splitting-schedule"
                component={SplittingSchedule}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/insurance-company"
                component={InsuranceCompany}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/product-group"
                component={ProductGroup}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/product"
                component={Product}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path={OperationLink.CustomerInvoiceApproval}
                component={CustomerInvoice}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/customer-invoices"
                component={CustomerInvoices}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/customer-invoice-reversal"
                component={CustomerInvoiceReversal}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path={OperationLink.VendorInvoiceApproval}
                component={VendorInvoice}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/vendor-invoices"
                component={VendorInvoices}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/vendor-invoice-reversal"
                component={VendorInvoiceReversal}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/asset-stock"
                component={AssetStock}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/asset-stocks"
                component={AssetStockList}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/revaluation-schedule"
                component={RevaluationSchedule}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/revaluation-schedules"
                component={RevaluationScheduleList}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/disposal-schedule"
                component={DisposalSchedule}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/disposal-schedules"
                component={DisposalScheduleList}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/depreciation-schedule"
                component={DepreciationSchedule}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/depreciation-schedules"
                component={DepreciationScheduleList}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/maintenance-schedule"
                component={MaintenanceSchedule}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/maintenance-schedules"
                component={MaintenanceScheduleList}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/asset"
                component={AssetItem}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/assets"
                component={AssetList}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/asset-class"
                component={AssetClass}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/user-profile"
                component={UserProfile}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/transaction-history"
                component={TransactionHistory}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/transaction-group"
                component={TransactionGroup}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/audit-trail"
                component={AuditTrail}
              />
              <Route
                exact={true}
                path="/new-password"
                component={NewPassword}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/fiscal-year"
                component={FiscalYear}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/company-config"
                component={CompanyConfig}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/accounting-period"
                component={AccountingPeriod}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/recurring-journals"
                component={RecurringJournalList}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/recurring-journal"
                component={RecurringJournal}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/journal-reversal"
                component={JournalReversal}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/journal"
                component={JournalVoucher}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/journals"
                component={Journal}
              />

              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/adjustment-journals"
                component={AdjustmentJournal}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/adjustment-journal"
                component={AdjustmentJournalVoucher}
              />

              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/opening-balance-journals"
                component={OpeningBalanceJournals}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/opening-balance-journal"
                component={OpeningBalanceJournal}
              />

              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/journal-template"
                component={JournalTemplate}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/accounts"
                component={Accounts}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/account-group"
                component={AccountGroup}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/bank"
                component={BankUI}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/bank-group"
                component={BankGroupUI}
              />

              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/role"
                component={RoleUI}
              />

              {/* <RouteGuard auth ={isAuthenticated}  exact={true} path="/active-users" component={ActiveUserUI} /> */}
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/account-class"
                component={AccountClass}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/chart-of-accounts"
                component={ChartOfAccounts}
              />
              <Route
                exact={true}
                path="/verification"
                component={Verification}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/create-admin"
                component={CreateAdmin}
              />
              <Route
                exact={true}
                path="/forgot-password"
                component={ForgotPassword}
              />
              {/* <RouteGuard auth ={isAuthenticated} component={ForgotPassword}  render={(props:any) => <ForgotPassword {...props} />} path="/forgot-password" /> */}
              <RouteGuard
                auth={isAuthenticated}
                component={SuccessPg}
                render={(props: any) => <SuccessPg {...props} />}
                path="/success"
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/new-contract"
                component={NewContract}
              />
              {hasActiveUser && !isAuthenticated && (
                <Route exact={true} path="/login" component={Login} />
              )}
              {hasActiveUser && !isAuthenticated && (
                <Route exact={true} path="/" component={Login} />
              )}
              {!hasActiveUser && (
                <Route exact={true} path="/user-setup" component={UserPage} />
              )}
              {!hasActiveUser && (
                <Route exact={true} path="/" component={UserPage} />
              )}

              <RouteGuard
                auth={isAuthenticated}
                path="/subscription"
                component={Subscription}
              />
              <RouteGuard
                exact={true}
                path="/home"
                component={Home}
                auth={isAuthenticated}
              />
              {hasActiveUser && isAuthenticated && (
                <RouteGuard
                  exact={true}
                  path="/"
                  component={Home}
                  auth={isAuthenticated}
                />
              )}
              <RouteGuard
                auth={isAuthenticated}
                path="/view-all"
                component={ViewAll}
              />
              <RouteGuard
                auth={isAuthenticated}
                path="/report-viewer"
                component={ReportViewer}
              />
              <RouteGuard
                auth={isAuthenticated}
                path="/report-designer"
                component={ReportDesigner}
              />
              <RouteGuard
                auth={isAuthenticated}
                path="/exchange-rate"
                component={Rate}
              />
              <RouteGuard
                auth={isAuthenticated}
                path="/dimension"
                component={Dimension}
              />
              <RouteGuard
                auth={isAuthenticated}
                path="/dimension-group"
                component={DimensionGroup}
              />
              <RouteGuard
                auth={isAuthenticated}
                path="/currency"
                component={CurrencyExchange}
              />
              <RouteGuard
                auth={isAuthenticated}
                path="/trial-balance"
                component={TrialBalance}
              />
              <RouteGuard
                auth={isAuthenticated}
                path="/number-sequence"
                component={NumberSequence}
              />
              <RouteGuard
                auth={isAuthenticated}
                path="/number-segment"
                component={NumberSequenceAdd}
              />
              {/* <RouteGuard auth ={isAuthenticated}  path="/number-sequence-id" component={NumberSequenceId} /> */}
              <RouteGuard
                auth={isAuthenticated}
                path="/posting-profile"
                component={PostingProfile}
              />
              <RouteGuard
                auth={isAuthenticated}
                path="/consolidation-mapping"
                component={ConsolidationMapping}
              />
              <RouteGuard
                auth={isAuthenticated}
                path="/consolidation"
                component={Consolidation}
              />
              <RouteGuard
                auth={isAuthenticated}
                path="/allocation-rule"
                component={AllocationRule}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/allocation"
                component={AllocationRule}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/message-setting"
                component={MessageSettings}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/service-monitor"
                component={ServiceMonitor}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/licensing"
                component={Licensing}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/license-manager"
                component={LicenseManager}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/contract-list"
                component={ContractList}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/contract"
                component={Contract}
              />
              <RouteGuard
                auth={isAuthenticated}
                path="/domain"
                component={CreateDomain}
              />
              <Route path="/sample" component={Sample} />
              <RouteGuard
                auth={isAuthenticated}
                path="/create-company"
                component={CreateCompany}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/organisation-structure"
                component={OrgStructure}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/tenant"
                component={TenantForm}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/company"
                component={CompanyForm}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/intercompany-journals"
                component={IntercompanyJournal}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/intercompany-journal-template"
                component={IntercompanyJournalTemplate}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/intercompany-journal"
                component={IntercompanyJournalVoucher}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/workflow"
                component={Workflow}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/workflow-chart"
                component={WorkflowChart}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/escalation"
                component={Escalation}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/workflow-tag"
                component={WorkflowTag}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/workflow-actor"
                component={WorkflowActor}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/workflow-task"
                component={WorkflowTask}
              />
              {/* <RouteGuard auth={isAuthenticated} exact={true} path="/customers" component={Customers} /> */}
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/customer"
                component={Customer}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/customer-group"
                component={CustomerGroup}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/vendor"
                component={Vendor}
              />
              {/* <RouteGuard auth={isAuthenticated} exact={true} path="/vendors" component={Vendors} /> */}
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/vendor-group"
                component={VendorGroup}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path={OperationLink.CustomerPaymentScheduleApproval}
                component={CustomerPaymentSchedule}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/customer-payment-schedules"
                component={CustomerPaymentSchedules}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/customer-recurring-payments"
                component={RecurringCustomerPayments}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/customer-recurring-payment"
                component={RecurringCustomerPayment}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path={OperationLink.VendorPaymentScheduleApproval}
                component={VendorPaymentSchedule}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/vendor-payment-schedules"
                component={VendorPaymentSchedules}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/vendor-recurring-payment"
                component={RecurringVendorPayment}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/vendor-recurring-payments"
                component={RecurringVendorPayments}
              />

              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/bank-reconciliation"
                component={BankReconciliation}
              />

              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/bank-reconciliations"
                component={BankReconciliations}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/fund-transfers"
                component={FundTransfers}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/fund-transfer"
                component={FundTransfer}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/cashflow-forecasts"
                component={CashflowForecasts}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/cashflow-forecast"
                component={CashflowForecast}
              />

              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/identity-group"
                component={IdentityGroup}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/identity"
                component={Identity}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/addresses"
                component={Address}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/site"
                component={Site}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/warehouse"
                component={Warehouse}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/location"
                component={Location}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/stock"
                component={Stock}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/stock-movement"
                component={StockMovement}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/stock-transfers"
                component={StockTransfers}
              />
              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path={OperationLink.StockTransferApproval}
                component={StockTransfer}
              />

              <RouteGuard
                auth={isAuthenticated}
                exact={true}
                path="/dashboard"
                component={Dashboard}
              />

              <Route component={PageNotFound} />
            </Switch>
          </Router>
        )}
      </Suspense>
    );
  }
}

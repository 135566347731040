import { Dimmer, Loader, Modal } from "semantic-ui-react";
import { AppButton, AppCard, AppColumn, AppForm, AppGrid, AppP, AppRow } from "../../../Global/Styles";
import { EntityState } from "../../../Models/EntityState";
import MainPage, { TopSideEnum } from "../../Components/MainPage/MainPage";
import UserPage from "./UserPage";
import "./UserPage.css";
import AppDiv from '../../../Global/Styles';

export const UserPageHTML = (pg: UserPage) => {
  const { activeUser, currentLoggedInUser, loading, eulaContent, tenant } = pg.state;
  const content = (): JSX.Element => {
    return (
      <>
        <Dimmer active={loading} inverted>
          <Loader size="large" style={{ color: "#004764" }}>
            Please wait ...
          </Loader>
        </Dimmer>
        {tenant &&
          <AppRow height="100%">
            <AppColumn span={8}>
              <AppRow className='content-center' height="100%" width="100%!important" padding="20px">
                <AppForm style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} width="100%!important">
                  <AppP
                    text_color="#1A202C"
                    font_weight="600"
                    font_size="16px"
                    margin_top="10px"
                    margin_bottom="10px"
                    style={{
                      width: '50%',
                      textAlign: 'center',
                      alignSelf: 'flex-start'
                    }}
                  >
                    Create User
                  </AppP>
                  <AppForm.Input
                    width={12}
                    onChange={(e, data) => pg.handleChange(e, data)}
                    label="Full Name"
                    placeholder="Enter Name"
                    name="name"
                    value={activeUser.name ?? ""}
                    required
                    fluid
                  />
                  <AppForm.Input
                    width={12}
                    disabled={
                      activeUser.state === EntityState.Unchanged
                    }
                    onChange={(e, data) => pg.handleChange(e, data)}
                    label="Email"
                    placeholder="Enter Email"
                    name="mailAddress"
                    required
                    value={activeUser.mailAddress ?? ""}
                    fluid
                  />

                  <AppForm.Input
                    width={12}
                    onChange={(e, data) => pg.handleChange(e, data)}
                    label="Password"
                    placeholder="Password"
                    name="password"
                    value={activeUser.password ?? ""}
                    fluid
                    required
                    type="password"
                  />
                  <AppForm.Input
                    width={12}
                    onChange={(e, data) => pg.handleChange(e, data)}
                    label="Confirm Password"
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    value={activeUser.confirmPassword ?? ""}
                    fluid
                    required
                    type="password"
                  />
                  {currentLoggedInUser &&
                    <AppForm.Select
                      width={12}
                      onChange={(e, data) => pg.handleChange(e, data)}
                      label="Company"
                      fluid
                      required
                      value={activeUser.company ?? ""}
                      name="company"
                      placeholder="Select Company"
                      options={pg.state.companies}
                    />
                  }
                  {currentLoggedInUser &&
                    <AppForm.Select
                      width={12}
                      disabled={activeUser.state === EntityState.New}
                      onChange={(e, data) => pg.handleChange(e, data)}
                      label="Role"
                      fluid
                      required
                      value={activeUser.roleID ?? ""}
                      name="roleID"
                      placeholder="Role"
                      options={pg.state.roleList}
                    />
                  }
                  {currentLoggedInUser &&
                    <AppForm.Select
                      width={12}
                      onChange={(e, data) => pg.handleChange(e, data)}
                      label="Status"
                      fluid
                      required
                      value={activeUser.status ?? ""}
                      name="userStatus"
                      placeholder="Status"
                      options={pg.state.status}
                    />
                  }
                  <AppRow width="100%" align="middle">
                    <AppColumn offset={8} span={8}>
                      <AppButton
                        width="100%"
                        margin_top="20px"
                        text_align="center"
                        onClick={() => pg.saveUser()}
                        size="small"
                        font_size="14px!important"
                        bg_color="#179ED4"
                        text_color="#ffffff"
                        fluid
                      >
                        {activeUser.state === EntityState.New
                          ? "Create User"
                          : "Update User"}
                      </AppButton>
                    </AppColumn>
                  </AppRow>
                </AppForm>
              </AppRow>
            </AppColumn>
            <AppColumn bg_color='#a8e5ff' span={16}>
              <AppRow className='pwdImage'>
                <img alt="" src='/images/create-admin-img.png' />
              </AppRow>
            </AppColumn>
          </AppRow >
        }
        <Modal
          style={{ height: '90%' }}
          size='small'
          open={pg.state.dlgOpen}
          onClose={() => pg.toggle(false)}
          onOpen={() => pg.toggle(true)}
        >
          <Modal.Header>End User License Agreement</Modal.Header>
          <Modal.Content scrolling>
            <AppDiv
              margin_top="5px!important"
              padding="10px"
              scroll="auto!important"
              dangerouslySetInnerHTML={{ __html: eulaContent }}
              height="100%!important"
            ></AppDiv>
          </Modal.Content>
          <Modal.Actions className='confirmFooter'>
            <AppButton
              text_color="white!important"
              bg_color='#006dff!important'
              onClick={() => pg.toggle(false)} >
              Accept
            </AppButton>
            <AppButton onClick={() => pg.toggle(true)}>
              Cancel
            </AppButton>
          </Modal.Actions>
        </Modal>
      </>

    );
  }
  return (
    <MainPage
      showHeader={false}
      showFooter={false}
      ref={pg.state.mainPageRef}
      pageName="User Page"
      myMenus={[]}
      topEnum={TopSideEnum.TopBar}
      breadCrumbs={[]}
      content={content()}
    />
  );
};

import { Helmet } from "react-helmet";
import { Container, Divider } from "semantic-ui-react";
import AppDiv, {
  AppGrid,
  AppGridColumn,
  AppGridRow,
  AppIcon,
  AppSpan,
} from "../../../Global/Styles";
import { Menu } from "../../../Models/Menu";
import Login from "../../Pages/Login/Login";
import Toolbar from "../Toolbar/Toolbar";
import TopSidebar from "../TopSidebar/TopSidebar";
import MainPage, { TopSideEnum } from "./MainPage";
import "./MainPage.css";

export const MainPageHTML = (pg: MainPage) => {
  return (
    <>
      <Helmet>
        <title>{pg.props.pageName}</title>
      </Helmet>
      {pg.props.topEnum === TopSideEnum.TopSideBar ? (
        <AppDiv >
          <Toolbar
            showTopBar={
              pg.props.content instanceof Login == false ? true : false
            }
            ref={pg.state.topBarRef}
            breadCrumbs={pg.props.breadCrumbs ? pg.props.breadCrumbs : pg.state.breadCrumbs}
          />

          <AppDiv
            position="absolute"
            bottom="40px!important"
            top="60px!important"
            className={`page ${pg.state.sidebarFold ? "folded" : "opened"}`}
          >
            {pg.state.sidebarFold ? (
              <AppDiv
                // height="100%!important"
                bg_color="#D1F2FF"
                padding_left="0px!important"
                padding_right="0px!important"
                // width={1}
                style={{
                  position: "relative",
                }}
                className="sidebar-conrol-column"
              >
                <TopSidebar
                  breadCrumbs={pg.props.breadCrumbs ? pg.props.breadCrumbs : pg.state.breadCrumbs}
                  menu={pg.props.myMenus ? pg.props.myMenus as Menu[] : pg.state.menus}
                  mainPage={pg}
                />
              </AppDiv>
            ) : (
              <AppDiv
                // height="100%!important"
                bg_color="#D1F2FF"
                padding_left="0px!important"
                padding_right="0px!important"
                style={{
                  position: "relative",
                }}
              // width={3}
              >
                <TopSidebar
                  breadCrumbs={pg.props.breadCrumbs ? pg.props.breadCrumbs : pg.state.breadCrumbs}
                  menu={pg.props.myMenus ? pg.props.myMenus as Menu[] : pg.state.menus}
                  mainPage={pg}
                />
              </AppDiv>
            )}
            <AppDiv
              height="100%!important"
              // position="relative"
              className="mpg-content"
              // padding="1rem !important"
            >
              {pg.props.content}
            </AppDiv>

          </AppDiv>
          {pg.props.showFooter && (
            <AppDiv
              position="absolute"
              bottom="0px"
              width="100%"
              className="mainPage-footer"
            >
              <Divider style={{ margin: "0" }} />
              <AppGrid
                verticalAlign="middle"
                padding_left="20px!important"
                height="100%"
                className="center-v-footer"
              >
                <AppSpan line_height="30px" height="30px">
                  &copy; 2021 Applause Version: 1.0
                </AppSpan>
              </AppGrid>
            </AppDiv>
          )}
        </AppDiv>
      ) : null
      }
      {
        pg.props.topEnum === TopSideEnum.TopBar ? (
          <AppDiv >
            <Toolbar
              showTopBar={pg.props.showHeader}
              ref={pg.state.topBarRef}
              breadCrumbs={pg.props.breadCrumbs ? pg.props.breadCrumbs : pg.state.breadCrumbs}
            />
            <AppDiv
              position="absolute"
              bottom="40px!important"
              top="60px!important"
              // padding="1rem !important"
              width="100%"
            >
              {pg.props.content}
            </AppDiv>
            {pg.props.showFooter && (
              <AppDiv
                position="absolute"
                bottom="0px"
                width="100%"
                className="mainPage-footer"
              >
                <Divider style={{ margin: "0" }} />
                <AppGrid
                  verticalAlign="middle"
                  padding_left="20px!important"
                  height="100%"
                  className="center-v-footer"
                >
                  <AppSpan line_height="30px" height="30px">
                    &copy; 2021 Applause Version: 1.0
                  </AppSpan>
                </AppGrid>
              </AppDiv>
            )}
          </AppDiv>
        ) : null
      }
    </>
  );
};

import Store, { AppKey } from "../Global/Store";
import { EntityState } from "./EntityState";
import { IEntity } from "./IEntity";
import { UserRole } from "./UserRole";
import { UserStatus } from "./UserStatus";

export class AuthUser implements IEntity{
    isChecked: boolean=false;
    constructor(){
        const store = new Store();
        var user = store.get(AppKey.USER) ;
        if(user){
            this.company= user.activeUser.company;
            this.tenant= user.activeUser.tenant;
        }
    }
    code: string="";
    company: string="";
    tenant: string="";
    createdAt= new  Date();
    updatedAt: Date= new  Date();
    state= EntityState.New;
    name = ""
    roleID = ""
    mailAddress = ""
    reason = ""
    phoneNumber=""
    role?:UserRole;
    lastLogin="";
    color="";
    password: string= ""
    confirmPassword: string= ""
    key: string=""
    value: string=""
    status= UserStatus.Active
}
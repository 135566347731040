import * as React from "react";
import "./Toolbar.css";
import { Breadcrumb, Dimmer, Divider, Label, Loader, Popup } from "semantic-ui-react";
import Toolbar from "./Toolbar";
import AppDiv, {
  AppDropdown,
  AppDropdownDivider,
  AppDropdownItem,
  AppDropdownMenu,
  AppGrid,
  AppGridColumn,
  AppGridRow,
  AppIcon,
  AppInput,
  AppMessage,
  AppP,
} from "../../../Global/Styles";
import { Link } from "react-router-dom";
import { AppKey } from "../../../Global/Store";
import moment from "moment";
import { Util } from "../../../Global/Util";

export const ToolBarHTML = (pg: Toolbar) => {
  const bc = pg.props.breadCrumbs;
  const { activeUser } = pg.state;
  return (
    <>
      <AppDiv
        // height="md:11vh;sl:9vh;"
        position="absolute"
        top="0px"
        height="60px!important"
        display={!pg.props.showTopBar || pg.state.showNotif ? "none" : "block"}
        width="100%"
      >
        <AppDiv
          // padding="5px 0px!important"
          className="rightBread"
          height="100% !important"
          width="100% !important"
        >
          <Breadcrumb className="app-breadcrumb" style={{ display: "flex", justifyContent: "center" }}>
            <Breadcrumb.Section className="my-auto">
              <Link id="home_lnk" to="/home">
                <AppGrid
                  center
                  verticalAlign="middle"
                  width="70px"
                  margin_bottom="5px!important"
                  margin_top="5px!important"
                >
                  <AppGridRow>
                    <AppGridColumn>
                      <img alt="Home" src="/images/app-logo.png" />
                    </AppGridColumn>
                  </AppGridRow>
                  <AppGridRow className="" font_size="12px">
                    <AppGridColumn>Home</AppGridColumn>
                  </AppGridRow>
                </AppGrid>
              </Link>
            </Breadcrumb.Section>
            {bc.length !== 0 ? (
              <Breadcrumb.Divider className="my-auto bc" icon="right chevron" />
            ) : null}
            {bc.map((breadCrumb: any, i) => (
              <React.Fragment key={i}>
                {bc.indexOf(breadCrumb) === bc.length - 1 ? (
                  <Breadcrumb.Section
                    key={i}
                    className="my-auto"
                    style={{ color: "#7C7C7C" }}
                    active
                  >
                    {breadCrumb.title}
                  </Breadcrumb.Section>
                ) : (
                  <>
                    <Breadcrumb.Section
                      style={{ color: "#099AD9" }}
                      className="my-auto bc"
                      link
                    >
                      <Link
                        to={{
                          pathname: breadCrumb.link,
                          state:
                            breadCrumb.link === "/view-all" &&
                            pg.store.get(AppKey.VIEWALL_STATE_DATA),
                        }}
                      >
                        {breadCrumb.title}
                      </Link>
                    </Breadcrumb.Section>
                    <Breadcrumb.Divider
                      className="my-auto bc"
                      icon="right chevron"
                    />
                  </>
                )}
              </React.Fragment>
            ))}
          </Breadcrumb>
          <AppDiv
            text_color="#7C7C7C"
            width="40%"
            className="icons"
            margin_right="10px !important"
            margin_left="auto !important"
          >
            <AppDiv margin="5px">
              <Dimmer active={pg.state.loadingCompany} inverted>
                <Loader size="small" style={{ color: "#1A202C" }}>
                  Please wait...
                </Loader>
              </Dimmer>
              <Label color='blue'>
                App Date
                <Label.Detail>
                  {pg.state.company &&
                    moment(
                      new Date(pg.state.company.accountingDate),
                      pg.state.company.accountingDate && pg.state.company.dateFormat
                        ? pg.state.company.dateFormat
                        : Util.dateFmt
                    ).toDate().toLocaleDateString()
                  }
                </Label.Detail>
              </Label>

            </AppDiv>
            <AppDiv className="off-mobile" width="25%" margin_right="25px">
              <AppInput
                text_color="#7C7C7C"
                fluid
                icon="search"
                placeholder="Search..."
              />
            </AppDiv>
            <AppIcon className="off-mobile"
              margin_right="25px !important"
              name="question circle outline"
              link
              size="large"
            />
            <AppIcon className="off-mobile"
              margin_right="25px !important"
              name="bell outline"
              link
              size="large"
            />
            <AppIcon className="off-mobile"
              margin_right="25px !important"
              name="sun outline"
              link
              size="large"
            />
            {/* <AppIcon onClick={()=>pg.show} margin_right="25px!important"  color='blue' name='user circle' link size='big'> */}
            <AppDropdown
              link
              icon={{
                name: "user circle",
                size: "big",
                link: true,
                color: "blue",
              }}
              floating
              direction="left"
            >
              <AppDropdownMenu>
                <AppDropdownItem display="flex!important">
                  <AppIcon
                    text_color="#1A202C"
                    name="user outline"
                    className="floated"
                  />
                  <AppDiv cursor="pointer" onClick={() => pg.toUserProfile()}>
                    {activeUser?.name}
                    <br />
                    <AppDiv text_color="#A1A1A1">
                      {activeUser?.mailAddress}
                    </AppDiv>
                  </AppDiv>
                </AppDropdownItem>
                <AppDropdownItem display="flex!important" onClick={() => pg.toDashboard()}>
                  <AppIcon
                    text_color="#1A202C"
                    name="user outline"
                    className="floated"
                  />
                  Dashboard
                </AppDropdownItem>
                {/* <AppDropdownDivider margin="0px!important" /> */}
                <AppDropdownItem
                  // onClick={() => pg.toLogin()}
                  font_size="12px"
                  text_color="#1A202C!important"
                >
                  <AppIcon
                    text_color="#1A202C"
                    name="file outline"
                    className="floated"
                  />
                  {activeUser?.company}
                  {/* Verbena Logic */}
                </AppDropdownItem>
                <AppDropdownItem
                  onClick={() => pg.toLogin()}
                  font_size="12px"
                  text_color="#1A202C!important"
                >
                  <AppIcon
                    text_color="#1A202C"
                    name="sign-out"
                    className="floated"
                  />
                  Logout
                </AppDropdownItem>
              </AppDropdownMenu>
            </AppDropdown>
            {/* </AppIcon> */}
            {/* <Popup position='bottom right' basic trigger={<AppIcon color='blue' name='user circle' link size='big' />} on='click'>
              <AppGrid divided >
                <AppGrid.Row>
                  <AppIcon

                    link
                    text_color="#1A202C"
                    name="pencil"
                    className="floated"
                  />
                  Edit
                </AppGrid.Row>
                <AppGrid.Row>
                  <AppIcon
                    link
                    onClick={() => pg.toLogin()}
                    text_color="#1A202C"
                    name="trash alternate outline"
                    className="floated"
                  />
                  Logout
                </AppGrid.Row>
              </AppGrid>
            </Popup> */}
          </AppDiv>
        </AppDiv>
        <Divider style={{ margin: "0" }} />
      </AppDiv>
      <AppDiv
        position="absolute"
        top="0px"
        height="60px!important"
        display={pg.state.showNotif ? "block" : "none"}
        width="100%"
      >
        <AppMessage
          height="100%"
          corner_radius="0px"
          text_color="#1A202C"
          bg_color={
            pg.state.isNotifSuccess
              ? "rgba(9, 154, 217, 0.25)"
              : "rgba(235, 87, 87, 0.25)"
          }
          icon
        >
          <AppIcon
            font_size="20px!important"
            name={pg.state.isNotifSuccess ? "check circle outline" : "dont"}
          />
          <AppMessage.Content>
            <AppMessage.Header>
              {pg.state?.isNotifSuccess
                ? pg.state.notifHeader ?? "Success"
                : pg.state.notifHeader ?? "Error"}
            </AppMessage.Header>
            <AppDiv
              dangerouslySetInnerHTML={{ __html: pg.state.notifBody as any }}
            ></AppDiv>
          </AppMessage.Content>
          <AppIcon
            onClick={() => pg.toggleNotif(false)}
            font_size="20px!important"
            name={"times circle outline"}
          />
        </AppMessage>
      </AppDiv>
    </>
  );
};

export enum WorkflowOperation {
  JournalApproval = "JournalApproval",
  PeriodClosing = "PeriodClosing",
  Workflow = "Workflow",

  FundTransfer = "FundTransfer",
  DepreciationSchedule = "DepreciationSchedule",
  RevaluationSchedule = "RevaluationSchedule",
  SplittingSchedule = "SplittingSchedule",
  DisposalSchedule = "DisposalSchedule",
  SalarySchedule = "SalarySchedule",

  StockTransferApproval = "StockTransferApproval",
  StockTransferReversalApproval = "StockTransferReversalApproval",
  CustomerInvoiceApproval = "CustomerInvoiceApproval",
  CustomerInvoiceReversalApproval = "CustomerInvoiceReversalApproval",
  CustomerPaymentApproval = "CustomerPaymentApproval",
  CustomerPaymentReversalApproval = "CustomerPaymentReversalApproval",
  CustomerPaymentScheduleApproval = "CustomerPaymentScheduleApproval",
  CustomerPaymentScheduleReversalApproval = "CustomerPaymentScheduleReversalApproval",
  RecurringCustomerPaymentApproval = "RecurringCustomerPaymentApproval",
  VendorInvoiceApproval = "VendorInvoiceApproval",
  VendorInvoiceReversalApproval = "VendorInvoiceReversalApproval",
  VendorPaymentApproval = "VendorPaymentApproval",
  VendorPaymentReversalApproval = "VendorPaymentReversalApproval",
  VendorPaymentScheduleApproval = "VendorPaymentScheduleApproval",
  VendorPaymentScheduleReversalApproval = "VendorPaymentScheduleReversalApproval",
  RecurringVendorPaymentApproval = "RecurringVendorPaymentApproval",
  AdjustmentJournal="AdjustmentJournal",

  /** Added as DEFAULT */
  None = ""
}

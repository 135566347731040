import React from "react";
import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Store, { AppKey } from "../../../Global/Store";
import { ToolBarHTML } from "./ToolbarHTML";
import history from '../BrowserHistory'
import { AuthUser } from "../../../Models/AuthUser";
import { Company } from "../../../Models/Company";
import { Util } from "../../../Global/Util";
import { ErrorResponse } from "../../../Models/ErrorResponse";
interface Props {
    breadCrumbs: {
        title: string;
        link: string;
    }[];
    showTopBar: boolean
    // history?: RouteComponentProps["history"];
}
export interface PageState {
    // link: string[];
    isNotifSuccess?: boolean,
    notifHeader?: string,
    notifBody?: string,
    showNotif?: boolean,
    activeUser?: AuthUser,
    company: Company | undefined
    loadingCompany: boolean
}

export default class Toolbar extends Component<Props, PageState> {
    store = new Store();
    constructor(props: Props) {
        super(props);
        this.state = {
            company: undefined,
            loadingCompany: false,
            showNotif: false,
            activeUser: this.store.get(AppKey.USER) ? this.store.get(AppKey.USER).activeUser as AuthUser : undefined
        }
    }
    async componentDidMount() {
        document.body.style.overflow = 'hidden'
        this.setState({ loadingCompany: true })
        var comp = await Util.getCompany();
        if (comp instanceof ErrorResponse == false) {
            this.setState({ loadingCompany: false, company: comp as Company });
        }
    }

    toggleNotif(status: boolean, isNotifSuccess: boolean = false, notifBody: string = "", notifHeader: string = "") {
        if (status) {
            //close after 3sec
            setTimeout(() => {
                this.setState(previousState => ({
                    ...previousState,
                    showNotif: false,
                }))
            }, 10000)
        }
        this.setState(previousState => ({
            ...previousState,
            showNotif: status,
            notifBody: notifBody ? notifBody : isNotifSuccess ? "Operation Successful" : "Error",
            notifHeader: notifHeader ? notifHeader : isNotifSuccess ? "Success" : "Error",
            isNotifSuccess: isNotifSuccess
        }))
    }


    toLogin() {
        const store = new Store();
        store.clearAll([AppKey.HasActiveUser]);
        history.push('/login')
        window.location.reload();
        // history.replace('/login');
    }
    toUserProfile() {
        history.replace('/user-profile');
    }

    toDashboard() {
        history.replace('/dashboard');
    }

    render() {
        return ToolBarHTML(this);
    }
}


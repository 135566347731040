export class Menu{
    id?="";
    name: string="";
    link: string="";
    active: boolean=false;
    disabled?:boolean=false;
    icon?="side_journal";
    children?:{name:string,link:string,active?:boolean,operations?:{name:string}[]}[]=[];
    breadCrumbs?:AppBreadCrumb[]=[]
    module?:string="";
    operations?:{name:string}[]=[];
}
export class AppBreadCrumb{
    title: string="";
    link: string="";
}
export class PageMenuBreadCrumb{
    menus: Menu[]=[];
    breadCrumbs: AppBreadCrumb[]=[];
    homeMenu:MenuServiceGroup[]=[]
    /**
     *
     */
    constructor( homeMenu:any[]) {
       this.homeMenu=homeMenu;
    }
}
export class MenuPage{
    pageMenu: Menu[]=[];
    url="";
    groupName=""
    name?=""
    id=""
    disabled= false;
    module?:string;

}
export class MenuServiceGroup{
    id="";
    module="";
    pages:MenuPage[]=[];
    reports:MenuReportItem[]=[];
    imageUrl= "";
    disabled= false;
    color="";
}
export class MenuReportItem{
    id=0;
    name="";
    url= "";
    extra:any;
    disabled= false;
}

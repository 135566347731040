import { Col, Row } from "antd-grid-layout";
import {
  Accordion,
  AccordionContent,
  AccordionPanel,
  AccordionTitle,
  Button,
  Card,
  Checkbox,
  Container,
  Divider,
  Dropdown,
  DropdownDivider,
  DropdownHeader,
  DropdownItem,
  DropdownMenu,
  Form,
  Grid,
  GridColumn,
  GridRow,
  Icon,
  Image,
  Input,
  Label,
  List,
  ListContent,
  ListDescription,
  ListHeader,
  ListIcon,
  ListItem,
  Message,
  Modal,
  Search,
  Segment,
  Select,
  Sidebar,
  Tab,
  Table,
  TableBody,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";
import styled, { css } from "styled-components";
import { StyleProps } from "./StyleProps";
import { InputNumber } from 'primereact/inputnumber';

const size = {
  xs: 320, //Small Mobile
  sm: 375, //Medium Mobile
  lm: 425, //Large Mobile
  md: 768, //tablet
  sl: 1024, //small Laptop
  ml: 1600, //medium laptop
  lg: 1800, //Laptop Large
  xl: 2560, //Desktop
};

export const device = {
  mobileS: `(max-width: ${size.sm - 1}px) and (min-width: ${size.xs}px)`,
  mobileM: `(max-width: ${size.lm - 1}px) and (min-width: ${size.sm}px)`,
  mobileL: `(max-width: ${size.md - 1}px) and (min-width: ${size.lm}px)`,
  tablet: `(max-width: ${size.sl - 1}px) and (min-width: ${size.md}px)`,
  laptop: `(max-width: ${size.ml - 1}px) and (min-width: ${size.sl}px)`,
  mdLaptop: `(max-width: ${size.lg}px) and (min-width: ${size.ml}px)`,
  laptopL: `(max-width: ${size.xl - 1}px) and (min-width: ${size.lg}px)`,
  desktop: `(min-width: ${size.xl}px)`,
  desktopL: `(min-width: ${size.xl}px)`,
};
const cssRules = css<StyleProps>`
   {
top: ${(props) => props.top && props.top};
left: ${(props) => props.left && props.left};
bottom: ${(props) => props.bottom && props.bottom};
right: ${(props) => props.right && props.right};
    position: ${(props) => props.position && props.position};
    background: ${(props) => props.bg_color && props.bg_color};
    cursor: ${(props) => props.cursor && props.cursor};
    width: ${(props) => props.width && props.width};
    margin: ${(props) => props.margin && props.margin};
    margin-top: ${(props) => props.margin_top && props.margin_top};
    margin-right: ${(props) => props.margin_right && props.margin_right};
    margin-bottom: ${(props) => props.margin_bottom && props.margin_bottom};
    margin-left: ${(props) => props.margin_left && props.margin_left};
    padding: ${(props) => props.padding && props.padding};
    padding-top: ${(props) => props.padding_top && props.padding_top};
    padding-right: ${(props) => props.padding_right && props.padding_right};
    padding-bottom: ${(props) => props.padding_bottom && props.padding_bottom};
    padding-left: ${(props) => props.padding_left && props.padding_left};
    border: ${(props) => props.border && props.border};
    border-top: ${(props) => props.border_top && props.border_top};
    border-right: ${(props) => props.border_right && props.border_right};
    border-bottom: ${(props) => props.border_bottom && props.border_bottom};
    border-left: ${(props) => props.border_left && props.border_left};
    color: ${(props) => (props.text_color ? props.text_color : "#1A202C")};
    box-shadow: ${(props) => props.box_shadow && props.box_shadow};
    border-radius: ${(props) => props.corner_radius && props.corner_radius};
    display: ${(props) => props.display && props.display};
    align-items: ${(props) => props.align_items && props.align_items};
    justify-content: ${(props) =>
    props.justify_content && props.justify_content};
    font-size: ${(props) => (props.font_size ? props.font_size : "12px")};
    line-height: ${(props) => props.line_height && props.line_height};
    ${(props) => {
    return (
      props.scroll &&
      css`
          overflow: ${props.scroll};
        `
    );
  }};
    overflow-y:${(props) => props.scroll_v && props.scroll_v};
    max-height:${(props) => props.max_height && props.max_height};
    max-width: ${(props) => props.max_width && props.max_width};
    min-width: ${(props) => props.min_width && props.min_width};
    min-height: ${(props) => props.min_height && props.min_height};
    text-align: ${(props) => props.text_align && props.text_align};
    vertical-align: ${(props) => props.vertical_align && props.vertical_align};
    height: ${(props) => props.height && props.height};
    ${(props) =>
    props.gap &&
    css`
        gap: ${props.gap};
      `}

    @media only screen and ${device.laptop} {
      height: ${(props) =>
    props.height &&
    props.height.split(";").findIndex((f) => f.includes("sl")) > -1 &&
    props.height
      .split(";")
    [props.height.split(";").findIndex((f) => f.includes("sl"))].split(
      ":"
    )[1]};
      max-height: ${(props) =>
    props.max_height &&
    props.max_height.split(";").findIndex((f) => f.includes("sl")) > -1 &&
    props.max_height
      .split(";")
    [
      props.max_height.split(";").findIndex((f) => f.includes("sl"))
    ].split(":")[1]};
      min-height: ${(props) =>
    props.min_height &&
    props.min_height.split(";").findIndex((f) => f.includes("sl")) > -1 &&
    props.min_height
      .split(";")
    [
      props.min_height.split(";").findIndex((f) => f.includes("sl"))
    ].split(":")[1]};
      max-width: ${(props) =>
    props.max_width &&
    props.max_width.split(";").findIndex((f) => f.includes("sl")) > -1 &&
    props.max_width
      .split(";")
    [props.max_width.split(";").findIndex((f) => f.includes("sl"))].split(
      ":"
    )[1]};
      min-width: ${(props) =>
    props.min_width &&
    props.min_width.split(";").findIndex((f) => f.includes("sl")) > -1 &&
    props.min_width
      .split(";")
    [props.min_width.split(";").findIndex((f) => f.includes("sl"))].split(
      ":"
    )[1]};
      padding: ${(props) =>
    props.padding &&
    props.padding.split(";").findIndex((f) => f.includes("sl")) > -1 &&
    props.padding
      .split(";")
    [props.padding.split(";").findIndex((f) => f.includes("sl"))].split(
      ":"
    )[1]};
      margin: ${(props) =>
    props.margin &&
    props.margin.split(";").findIndex((f) => f.includes("sl")) > -1 &&
    props.margin
      .split(";")
    [props.margin.split(";").findIndex((f) => f.includes("sl"))].split(
      ":"
    )[1]};
    }

    @media only screen and ${device.tablet} {
      height: ${(props) =>
    props.height &&
    props.height.split(";").findIndex((f) => f.includes("md")) > -1 &&
    props.height
      .split(";")
    [props.height.split(";").findIndex((f) => f.includes("md"))].split(
      ":"
    )[1]};
      max-height: ${(props) =>
    props.max_height &&
    props.max_height.split(";").findIndex((f) => f.includes("md")) > -1 &&
    props.max_height
      .split(";")
    [
      props.max_height.split(";").findIndex((f) => f.includes("md"))
    ].split(":")[1]};
      min-height: ${(props) =>
    props.min_height &&
    props.min_height.split(";").findIndex((f) => f.includes("md")) > -1 &&
    props.min_height
      .split(";")
    [
      props.min_height.split(";").findIndex((f) => f.includes("md"))
    ].split(":")[1]};
      max-width: ${(props) =>
    props.max_width &&
    props.max_width.split(";").findIndex((f) => f.includes("md")) > -1 &&
    props.max_width
      .split(";")
    [props.max_width.split(";").findIndex((f) => f.includes("md"))].split(
      ":"
    )[1]};
      min-width: ${(props) =>
    props.min_width &&
    props.min_width.split(";").findIndex((f) => f.includes("md")) > -1 &&
    props.min_width
      .split(";")
    [props.min_width.split(";").findIndex((f) => f.includes("md"))].split(
      ":"
    )[1]};
      padding: ${(props) =>
    props.padding &&
    props.padding.split(";").findIndex((f) => f.includes("md")) > -1 &&
    props.padding
      .split(";")
    [props.padding.split(";").findIndex((f) => f.includes("md"))].split(
      ":"
    )[1]};
      margin: ${(props) =>
    props.margin &&
    props.margin.split(";").findIndex((f) => f.includes("md")) > -1 &&
    props.margin
      .split(";")
    [props.margin.split(";").findIndex((f) => f.includes("md"))].split(
      ":"
    )[1]};
    }

    @media only screen and ${device.mdLaptop} {
      height: ${(props) =>
    props.height &&
    props.height.split(";").findIndex((f) => f.includes("ml")) > -1 &&
    props.height
      .split(";")
    [props.height.split(";").findIndex((f) => f.includes("ml"))].split(
      ":"
    )[1]};
      max-height: ${(props) =>
    props.max_height &&
    props.max_height.split(";").findIndex((f) => f.includes("ml")) > -1 &&
    props.max_height
      .split(";")
    [
      props.max_height.split(";").findIndex((f) => f.includes("ml"))
    ].split(":")[1]};
      min-height: ${(props) =>
    props.min_height &&
    props.min_height.split(";").findIndex((f) => f.includes("ml")) > -1 &&
    props.min_height
      .split(";")
    [
      props.min_height.split(";").findIndex((f) => f.includes("ml"))
    ].split(":")[1]};
      max-width: ${(props) =>
    props.max_width &&
    props.max_width.split(";").findIndex((f) => f.includes("ml")) > -1 &&
    props.max_width
      .split(";")
    [props.max_width.split(";").findIndex((f) => f.includes("ml"))].split(
      ":"
    )[1]};
      min-width: ${(props) =>
    props.min_width &&
    props.min_width.split(";").findIndex((f) => f.includes("ml")) > -1 &&
    props.min_width
      .split(";")
    [props.min_width.split(";").findIndex((f) => f.includes("ml"))].split(
      ":"
    )[1]};
      padding: ${(props) =>
    props.padding &&
    props.padding.split(";").findIndex((f) => f.includes("ml")) > -1 &&
    props.padding
      .split(";")
    [props.padding.split(";").findIndex((f) => f.includes("ml"))].split(
      ":"
    )[1]};
      margin: ${(props) =>
    props.margin &&
    props.margin.split(";").findIndex((f) => f.includes("ml")) > -1 &&
    props.margin
      .split(";")
    [props.margin.split(";").findIndex((f) => f.includes("ml"))].split(
      ":"
    )[1]};
    }

    @media only screen and ${device.laptopL} {
      height: ${(props) =>
    props.height &&
    props.height.split(";").findIndex((f) => f.includes("lg")) > -1 &&
    props.height
      .split(";")
    [props.height.split(";").findIndex((f) => f.includes("lg"))].split(
      ":"
    )[1]};
      max-height: ${(props) =>
    props.max_height &&
    props.max_height.split(";").findIndex((f) => f.includes("lg")) > -1 &&
    props.max_height
      .split(";")
    [
      props.max_height.split(";").findIndex((f) => f.includes("lg"))
    ].split(":")[1]};
      min-height: ${(props) =>
    props.min_height &&
    props.min_height.split(";").findIndex((f) => f.includes("lg")) > -1 &&
    props.min_height
      .split(";")
    [
      props.min_height.split(";").findIndex((f) => f.includes("lg"))
    ].split(":")[1]};
      max-width: ${(props) =>
    props.max_width &&
    props.max_width.split(";").findIndex((f) => f.includes("lg")) > -1 &&
    props.max_width
      .split(";")
    [props.max_width.split(";").findIndex((f) => f.includes("lg"))].split(
      ":"
    )[1]};
      min-width: ${(props) =>
    props.min_width &&
    props.min_width.split(";").findIndex((f) => f.includes("lg")) > -1 &&
    props.min_width
      .split(";")
    [props.min_width.split(";").findIndex((f) => f.includes("lg"))].split(
      ":"
    )[1]};
      padding: ${(props) =>
    props.padding &&
    props.padding.split(";").findIndex((f) => f.includes("lg")) > -1 &&
    props.padding
      .split(";")
    [props.padding.split(";").findIndex((f) => f.includes("lg"))].split(
      ":"
    )[1]};
      margin: ${(props) =>
    props.margin &&
    props.margin.split(";").findIndex((f) => f.includes("lg")) > -1 &&
    props.margin
      .split(";")
    [props.margin.split(";").findIndex((f) => f.includes("lg"))].split(
      ":"
    )[1]};
    }

    @media only screen and ${device.desktop} {
      height: ${(props) =>
    props.height &&
    props.height.split(";").findIndex((f) => f.includes("xl")) > -1 &&
    props.height
      .split(";")
    [props.height.split(";").findIndex((f) => f.includes("xl"))].split(
      ":"
    )[1]};
      max-height: ${(props) =>
    props.max_height &&
    props.max_height.split(";").findIndex((f) => f.includes("xl")) > -1 &&
    props.max_height
      .split(";")
    [
      props.max_height.split(";").findIndex((f) => f.includes("xl"))
    ].split(":")[1]};
      min-height: ${(props) =>
    props.min_height &&
    props.min_height.split(";").findIndex((f) => f.includes("xl")) > -1 &&
    props.min_height
      .split(";")
    [
      props.min_height.split(";").findIndex((f) => f.includes("xl"))
    ].split(":")[1]};
      max-width: ${(props) =>
    props.max_width &&
    props.max_width.split(";").findIndex((f) => f.includes("xl")) > -1 &&
    props.max_width
      .split(";")
    [props.max_width.split(";").findIndex((f) => f.includes("xl"))].split(
      ":"
    )[1]};
      min-width: ${(props) =>
    props.min_width &&
    props.min_width.split(";").findIndex((f) => f.includes("xl")) > -1 &&
    props.min_width
      .split(";")
    [props.min_width.split(";").findIndex((f) => f.includes("xl"))].split(
      ":"
    )[1]};
      padding: ${(props) =>
    props.padding &&
    props.padding.split(";").findIndex((f) => f.includes("xl")) > -1 &&
    props.padding
      .split(";")
    [props.padding.split(";").findIndex((f) => f.includes("xl"))].split(
      ":"
    )[1]};
      margin: ${(props) =>
    props.margin &&
    props.margin.split(";").findIndex((f) => f.includes("xl")) > -1 &&
    props.margin
      .split(";")
    [props.margin.split(";").findIndex((f) => f.includes("xl"))].split(
      ":"
    )[1]};
    }

    @media only screen and ${device.desktopL} {
      height: ${(props) =>
    props.height &&
    props.height.split(";").findIndex((f) => f.includes("xl")) > -1 &&
    props.height
      .split(";")
    [props.height.split(";").findIndex((f) => f.includes("xl"))].split(
      ":"
    )[1]};
      max-height: ${(props) =>
    props.max_height &&
    props.max_height.split(";").findIndex((f) => f.includes("xl")) > -1 &&
    props.max_height
      .split(";")
    [
      props.max_height.split(";").findIndex((f) => f.includes("xl"))
    ].split(":")[1]};
      min-height: ${(props) =>
    props.min_height &&
    props.min_height.split(";").findIndex((f) => f.includes("xl")) > -1 &&
    props.min_height
      .split(";")
    [
      props.min_height.split(";").findIndex((f) => f.includes("xl"))
    ].split(":")[1]};
      max-width: ${(props) =>
    props.max_width &&
    props.max_width.split(";").findIndex((f) => f.includes("xl")) > -1 &&
    props.max_width
      .split(";")
    [props.max_width.split(";").findIndex((f) => f.includes("xl"))].split(
      ":"
    )[1]};
      min-width: ${(props) =>
    props.min_width &&
    props.min_width.split(";").findIndex((f) => f.includes("xl")) > -1 &&
    props.min_width
      .split(";")
    [props.min_width.split(";").findIndex((f) => f.includes("xl"))].split(
      ":"
    )[1]};
      padding: ${(props) =>
    props.padding &&
    props.padding.split(";").findIndex((f) => f.includes("xl")) > -1 &&
    props.padding
      .split(";")
    [props.padding.split(";").findIndex((f) => f.includes("xl"))].split(
      ":"
    )[1]};
      margin: ${(props) =>
    props.margin &&
    props.margin.split(";").findIndex((f) => f.includes("xl")) > -1 &&
    props.margin
      .split(";")
    [props.margin.split(";").findIndex((f) => f.includes("xl"))].split(
      ":"
    )[1]};
    }
  }
`;

export const AppTable = styled(Table) <StyleProps>`
  && {
    ${cssRules}
    border-radius: ${(props) =>
    props.corner_radius ? props.corner_radius : ""};
  }
`;
export const AppTableBody = styled(Table.Body)`
  && {
    ${cssRules}
  }
`;
export const AppTableRow = styled(Table.Row)`
  && {
    ${cssRules}
  }
`;
export const AppTableHeader = styled(Table.Header) <StyleProps>`
  && {
    ${cssRules}
    border-radius: ${(props) => props.corner_radius && props.corner_radius};
  }
`;
export const AppTableHeaderCell = styled(Table.HeaderCell)`
  && {
    ${cssRules}
  }
`;
export const AppTableCell = styled(Table.Cell) <StyleProps>`
  && {
    ${cssRules}
    border-radius: ${(props) => props.corner_radius && props.corner_radius};
  }
`;

export const AppNumInput = styled(InputNumber)`
  && {
    ${cssRules}
  }
`;
export const AppGridColumn = styled(GridColumn)`
  && {
    ${cssRules}
  }
`;
export const AppDivider = styled(Divider)`
  && {
    ${cssRules}
  }
`;
export const AppGridRow = styled(GridRow)`
  && {
    ${cssRules}
  }
`;
export const AppRow = styled(Row)`
  && {
    ${cssRules}
  }
`;
export const AppColumn = styled(Col)`
  && {
    ${cssRules}
  }
`;

export const AppImg = styled(Image)`
  && {
    ${cssRules}
  }
`;
export const AppSelect = styled(Select)`
  && {
    ${cssRules}
  }
`;
export const AppMessage = styled(Message)`
  && {
    ${cssRules}
  }
`;
export const AppDropdownItem = styled(DropdownItem)`
  && {
    ${cssRules}
  }
`;
export const AppDropdownHeader = styled(DropdownHeader)`
  && {
    ${cssRules}
  }
`;
export const AppDropdownMenu = styled(DropdownMenu)`
  && {
    ${cssRules}
  }
`;
export const AppDropdown = styled(Dropdown)`
  && {
    ${cssRules}
  }
`;
export const AppDropdownDivider = styled(DropdownDivider)`
  && {
    ${cssRules}
  }
`;
export const AppCheckbox = styled(Checkbox)`
  && {
    ${cssRules}
  }
`;
export const AppIcon = styled(Icon)`
  && {
    ${cssRules}
  }
`;

export const AppLabel = styled(Label)`
  && {
    ${cssRules}
    font-weight: ${(props) => props.font_weight && props.font_weight};
  }
`;

export const AppAccordion = styled(Accordion)`
  && {
    ${cssRules}
  }
`;
export const AppAccordionTitle = styled(AccordionTitle)`
  && {
    ${cssRules}
  }
`;
export const AppAccordionPannel = styled(AccordionPanel)`
  && {
    ${cssRules}
  }
`;

export const AppAccordionContent = styled(AccordionContent)`
  && {
    ${cssRules}
  }
`;

export const AppGrid = styled(Grid)`
  && {
    ${cssRules}
    ${(props) => {
    return (
      props.gap &&
      css`
          gap: ${props.gap};
        `
    );
  }}
  }
`;
export const AppSegment = styled(Segment)`
  && {
    ${cssRules}
  }
`;
export const AppList = styled(List)`
  && {
    ${cssRules}
  }
`;
export const AppListItem = styled(ListItem)`
  && {
    ${cssRules}
  }
`;
export const AppListIcon = styled(ListIcon)`
  && {
    ${cssRules}
  }
`;

export const AppListContent = styled(ListContent)`
  && {
    ${cssRules}
  }
`;
export const AppListHeader = styled(ListHeader)`
  && {
    ${cssRules}
  }
`;
export const AppListDescription = styled(ListDescription)`
  && {
    ${cssRules}
  }
`;

export const AppDiv = styled.div<StyleProps>`
   {
    ${cssRules}
    font-family: ${(props) =>
    props.font_family ? props.font_family : "Montserrat"};
  }
`;

export const AppCard = styled(Card)`
  && {
    ${cssRules}
  }
`;
export const AppContainer = styled(Container)`
  && {
    ${cssRules}
  }
`;

export const AppInput = styled(Input) <StyleProps>`
  && {
    ${cssRules}
  }
`;
export const AppFormGroup = styled(Form.Group) <StyleProps>`
  && {
    ${cssRules}
  }
`;
export const AppForm = styled(Form) <StyleProps>`
  && {
    ${cssRules}
  }
`;
export const AppFormInput = styled(Form.Input) <StyleProps>`
  && {
    ${cssRules}
    border-radius: ${(props) => props.corner_radius && props.corner_radius};
  }
`;
export const AppTab = styled(Tab) <StyleProps>`
  && {
    ${cssRules}
    border-radius: ${(props) => props.corner_radius && props.corner_radius};
  }
`;
export const AppSideBar = styled(Sidebar) <StyleProps>`
  && {
    ${cssRules}
    border-radius: ${(props) =>
    props.corner_radius ? props.corner_radius : "50px"};
  }
`;

export const AppH4 = styled.h4<StyleProps>`
   {
    ${cssRules}
    font-family: ${(props) =>
    props.font_family ? props.font_family : "Montserrat"};
    font-weight: ${(props) =>
    props.font_weight ? props.font_weight : "normal"};
    font-style: ${(props) => (props.font_style ? props.font_style : "normal")};
    text-align: ${(props) => (props.text_align ? props.text_align : "left")};
  }
`;
export const AppSpan = styled.span<StyleProps>`
  && {
    ${cssRules}
    font-family: ${(props) =>
    props.font_family ? props.font_family : "Montserrat"};
    font-weight: ${(props) =>
    props.font_weight ? props.font_weight : "normal"};
    font-style: ${(props) => (props.font_style ? props.font_style : "normal")};
    text-align: ${(props) => (props.text_align ? props.text_align : "left")};
  }
`;
export const AppH1 = styled.h1<StyleProps>`
   {
    ${cssRules}
    font-family: ${(props) =>
    props.font_family ? props.font_family : "Montserrat"};
    font-weight: ${(props) =>
    props.font_weight ? props.font_weight : "normal"};
    font-style: ${(props) => (props.font_style ? props.font_style : "normal")};
    // font-size: ${(props) => (props.font_size ? props.font_size : "16px")};
    text-align: ${(props) => (props.text_align ? props.text_align : "left")};
  }
`;

export const AppP = styled.p<StyleProps>`
  && {
    ${cssRules}
    font-family: ${(props) =>
    props.font_family ? props.font_family : "Montserrat"};
    font-weight: ${(props) =>
    props.font_weight ? props.font_weight : "normal"};
    font-style: ${(props) => (props.font_style ? props.font_style : "normal")};
    // font-size: ${(props) => (props.font_size ? props.font_size : "16px")};
    text-align: ${(props) => (props.text_align ? props.text_align : "left")};
    box-shadow: ${(props) => (props.box_shadow ? props.box_shadow : "")};
    border-radius: ${(props) =>
    props.corner_radius ? props.corner_radius : "5px!"};
  }
`;
export const AppUl = styled.ul<StyleProps>`
  && {
    ${cssRules}
    font-family: ${(props) =>
    props.font_family ? props.font_family : "Montserrat"};
    font-weight: ${(props) =>
    props.font_weight ? props.font_weight : "normal"};
    font-style: ${(props) => (props.font_style ? props.font_style : "normal")};
    // font-size: ${(props) => (props.font_size ? props.font_size : "16px")};
    text-align: ${(props) => (props.text_align ? props.text_align : "left")};
    box-shadow: ${(props) => (props.box_shadow ? props.box_shadow : "")};
    border-radius: ${(props) =>
    props.corner_radius ? props.corner_radius : ""};
  }
`;
export const AppLi = styled.li<StyleProps>`
  && {
    ${cssRules}
    font-family: ${(props) =>
    props.font_family ? props.font_family : "Montserrat"};
    font-weight: ${(props) =>
    props.font_weight ? props.font_weight : "normal"};
    font-style: ${(props) => (props.font_style ? props.font_style : "normal")};
    // font-size: ${(props) => (props.font_size ? props.font_size : "16px")};
    text-align: ${(props) => (props.text_align ? props.text_align : "left")};

    border-radius: ${(props) =>
    props.corner_radius ? props.corner_radius : ""};
  }
`;

export const AppButton = styled(Button) <StyleProps>`
  && {
    ${cssRules}
    color: ${(props) => (props.text_color ? props.text_color : "green")};
    cursor: ${(props) => (props.cursor ? props.cursor : "pointer")};
    border-radius: ${(props) =>
    props.corner_radius ? props.corner_radius : "5px"};
    border: ${(props) => (props.border ? props.border : "none")};
    font-family: ${(props) =>
    props.font_family ? props.font_family : "Montserrat"};
    // font-size: ${(props) => (props.font_size ? props.font_size : "16px")};
    font-weight: ${(props) =>
    props.font_weight ? props.font_weight : "normal"};
  }
`;

export const AppSearch = styled(Search) <StyleProps>`
  && {
    ${cssRules}
    color: ${(props) => (props.text_color ? props.text_color : "green")};
    border-radius: ${(props) =>
    props.corner_radius ? props.corner_radius : "5px!important"};
    border: ${(props) => (props.border ? props.border : "none")};
    font-family: ${(props) =>
    props.font_family ? props.font_family : "Montserrat"};
    font-weight: ${(props) =>
    props.font_weight ? props.font_weight : "normal"};
  }
`;

export const AppModal = styled(Modal) <StyleProps>`
  && {
    ${cssRules}
  }
`;

export const AppModalHeader = styled(Modal.Header) <StyleProps>`
  && {
    ${cssRules}
  }
`;

export const AppModalContent = styled(Modal.Content) <StyleProps>`
  && {
    ${cssRules}
  }
`;

export const AppModalActions = styled(Modal.Actions) <StyleProps>`
  && {
    ${cssRules}
  }
`;

export default AppDiv;

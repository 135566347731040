import React, { Component } from "react";
import { Util } from "../../../Global/Util";
import { AppBreadCrumb, Menu } from "../../../Models/Menu";
import MainPage from "../MainPage/MainPage";
import Toolbar from "../Toolbar/Toolbar";
import { TopSidebarHTML } from "./TopSidebarHTML";
interface Props {
  breadCrumbs: AppBreadCrumb[];
  myHtml?: any;
  menu: Menu[];
  mainPage: MainPage;

  // history: RouteComponentProps["history"];
}
export interface PageState {
  link: string[];
  topBarRef: any;
  pinned: boolean;
}

export default class TopSidebar extends Component<Props, PageState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      link: ["Features", "General Ledger", "Charts of Accounts"],
      topBarRef: React.createRef(),
      pinned: false,
    };

    Util.log(this.props.menu);
  }
  toggleNotif(
    status: boolean,
    isNotifSuccess: boolean = false,
    notifBody: string = "",
    notifHeader: string = ""
  ) {
    (this.state.topBarRef.current as Toolbar).toggleNotif(
      status,
      isNotifSuccess,
      notifBody,
      notifHeader
    );
  }

  render() {
    return TopSidebarHTML(this);
  }
}

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "semantic-ui-css/semantic.min.css";
import App from "./Views/Pages/App/App";
// import { createRoot } from "react-dom/client";
import AppDiv from "./Global/Styles";
// const rootElement = document.getElementById("root");
// if (!rootElement) throw new Error('Failed to find the root element');
// const root = createRoot(rootElement);
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(Util.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// var status = "Please wait...";
//     const result = await new AuthService().checkIfUserExistinTenant();
//     if (result instanceof ErrorResponse) {
//       return (<AppDiv>Unable to validate tenant registration</AppDiv>)
//     }
//     else {
//       if (result == false) {
//         new Store().set(AppKey.HasActiveUser, '1');
//         status = "Initializing...";
//       }
//       else {
//         return (<AppDiv>Unable to validate tenant registration</AppDiv>)
//       }
//     }



import { AppBreadCrumb, Menu, MenuReportItem, MenuServiceGroup, PageMenuBreadCrumb } from "../../Models/Menu";
import { RolePageAction } from "../Pages/Role/RolePageAction";


  export class AppMenuConfig{
    static getAppMenuConfig(module:string,groupName:string, pageName:string):PageMenuBreadCrumb{
    const data = [
      //Admin
    {
      module:RolePageAction.Admin.toString(),
        pages:[
        //OrgStructure
        {
        groupName: RolePageAction.OrgStructure.toString(),
        pageMenu:[
          //OrgStructure 
          {
          children:[{name:RolePageAction.Tenant.toString(),link:"/organisation-structure/tenant"},{name:RolePageAction.Company.toString(),link:"/organisation-structure/company"}],
          name:RolePageAction.OrgStructure.toString(),
          link: "/organisation-structure",
          active: false,
          icon: "side_accountgroup.svg",
          breadCrumbs: [
          {
            title: RolePageAction.Admin.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.OrgStructure.toString(),
            link: "/organisation-structure",
          }
          ],
           operations:
                                        [
                                            {
                                                name: RolePageAction.SaveTenant.toString()
                                            },
                                            {
                                                name: RolePageAction.EditTenant.toString()
                                            },
                                            {
                                                name: RolePageAction.DeleteTenant.toString()
                                            },
                                            {
                                                name: RolePageAction.SaveCompany.toString()
                                            },
                                            {
                                                name: RolePageAction.EditCompany.toString()
                                            },
                                            {
                                                name: RolePageAction.DeleteCompany.toString()
                                            }
           ]
          },
        ],
         url:"organisation-structure",
         id:"organisation-structure",
         disabled:false
        },
         //RolePermission
        {
        groupName: RolePageAction.RolePermission.toString(),
        pageMenu:[
           //RolePermission 
          {
          children:[{name:RolePageAction.UserProfile.toString(),link:"/role"}],
          name:RolePageAction.RolePermission.toString(),
          link: "/role",
          active: false,
          icon: "side_role.svg",
          breadCrumbs: [
          {
            title: RolePageAction.Admin.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.RolePermission.toString(),
            link: "/role",
          }
          ],
           operations: [
                                        {
                                            name: RolePageAction.SaveRole.toString()
                                        },
                                        {
                                            name: RolePageAction.EditRole.toString()
                                        },
                                        {
                                            name: RolePageAction.DeleteRole.toString()
                                        },
                                        {
                                            name: RolePageAction.SaveUser.toString()
                                        },
                                        {
                                            name: RolePageAction.EditUser.toString()
                                        },
                                        {
                                            name: RolePageAction.DeleteUser.toString()
                                        },
                                    ]
          },
        ],
         url:"role",
         id:"role",
         disabled:false
        },
        //NumberSequence
        {
        groupName: RolePageAction.NumberSequence.toString(),
        pageMenu:[
           //NumberSequence 
          {
          children:[{name:RolePageAction.NumberSegment.toString(),link:"/number-segment"}],
          name:RolePageAction.NumberSequence.toString(),
          link: "/number-sequence",
          active: false,
          breadCrumbs: [
          {
            title: RolePageAction.Admin.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.NumberSequence.toString(),
            link: "/number-sequence",
          }
          ],
          operations: [
                                        {
                                            name: RolePageAction.SaveNumberSequence.toString()
                                        },
                                        {
                                            name: RolePageAction.EditNumberSequence.toString()
                                        },
                                        {
                                            name: RolePageAction.DeleteNumberSequence.toString()
                                        }
          ]
          },
         ],
         url:"number-sequence",
         id:"number-sequence",
         disabled:false
        },
        //Dimension
        {
        groupName: RolePageAction.Dimension.toString(),
         pageMenu:[
           //Dimension 
          {
          children:[],
          name:RolePageAction.Dimension.toString(),
          link: "/dimension",
          active: false,
          icon: "side_role.svg",
          breadCrumbs: [
          {
            title: RolePageAction.Admin.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.Dimension.toString(),
            link: "/dimension",
          }
          ],
          operations: [
                                        {
                                            name: RolePageAction.SaveDimension.toString()
                                        },
                                        {
                                            name: RolePageAction.EditDimension.toString()
                                        },
                                        {
                                            name: RolePageAction.DeleteDimension.toString()
                                        },
                                        {
                                            name: RolePageAction.ImportDimension.toString()
                                        },
                                        {
                                            name: RolePageAction.ExportDimension.toString()
                                        }
           ]
          },
          //Dimension Group
          {
          children:[],
          name:RolePageAction.DimensionGroup.toString(),
          link: "/dimension-group",
          active: false,
          icon: "side_role.svg",
          breadCrumbs: [
          {
            title: RolePageAction.Admin.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.DimensionGroup.toString(),
            link: "/dimension-group",
          }
          ]
          },
         ],
         url:"dimension",
         id:"dimension",
         disabled:false
        },
        //ServiceMonitor
        {
        groupName: RolePageAction.ServiceMonitor.toString(),
        pageMenu:[
           //ServiceMonitor 
          {
          name:RolePageAction.ServiceMonitor.toString(),
          link: "/service-monitor",
          active: false,
          breadCrumbs: [
          {
            title: RolePageAction.Admin.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.NumberSequence.toString(),
            link: "/service-monitor",
          }
          ]
          },
        ],
         url:"service-monitor",
         id:"service-monitor",
         disabled:false
        },
         //ReportDesigner
        {
        groupName: RolePageAction.ReportDesigner.toString(),
        pageMenu:[
           //ReportDesigner 
          {
          name:RolePageAction.ReportDesigner.toString(),
          link: "/report-designer",
          active: false,
          breadCrumbs: [
          {
            title: RolePageAction.Admin.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.ReportDesigner.toString(),
            link: "/report-designer",
          }
         ],
         operations: [
                                        {
                                            name: RolePageAction.SaveReport.toString()
                                        },
                                        {
                                            name: RolePageAction.EditReport.toString()
                                        },
                                        {
                                            name: RolePageAction.DeleteReport.toString()
                                        },
                                        {
                                            name: RolePageAction.SaveReportSchedule.toString()
                                        },
                                        {
                                            name: RolePageAction.EditReportSchedule.toString()
                                        },
                                        {
                                            name: RolePageAction.DeleteReportSchedule.toString()
                                        }
         ]
          },
        ],
         url:"report-designer",
         id:"report-designer",
         disabled:false
        },
        //CompanyConfig
        {
        groupName: RolePageAction.CompanyConfig.toString(),
        pageMenu:[
          //CompanyConfig 
          {
          name:RolePageAction.CompanyConfig.toString(),
          link: "/company-config",
          active: false,
          breadCrumbs: [
          {
            title: RolePageAction.Admin.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.CompanyConfig.toString(),
            link: "/company-config",
          }
          ],
               operations: [
                                        {
                                            name: RolePageAction.SaveCompanyConfig.toString()
                                        },
                                        {
                                            name: RolePageAction.EditCompanyConfig.toString()
                                        },
                                        {
                                            name: RolePageAction.DeleteCompanyConfig.toString()
                                        },
                                        {
                                            name: RolePageAction.ImportCompanyConfig.toString()
                                        },
                                        {
                                            name: RolePageAction.ExportCompanyConfig.toString()
                                        }
                                    ]
          },
        ],
         url:"company-config",
         id:"company-config",
         disabled:false
        },
         //Workflow
        {
        groupName: RolePageAction.Workflow.toString(),
        pageMenu:[
           //Workflow 
          {
          children:[{name:RolePageAction.WorkflowChart.toString(),link:"/workflow-chart"}],
          name:RolePageAction.Workflow.toString(),
          link: "/workflow",
          active: false,
          icon: "side_workflow.svg",
          breadCrumbs: [
          {
            title: RolePageAction.Admin.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.Workflow.toString(),
            link: "/workflow",
          }
          ],
             operations: [
                                        {
                                            name: RolePageAction.SaveWorkflow.toString()
                                        },
                                        {
                                            name: RolePageAction.EditWorkflow.toString()
                                        },
                                        {
                                            name: RolePageAction.DeleteWorkflow.toString()
                                        }
                                    ]
          },
          //WorkflowTag
          {
          name:RolePageAction.WorkflowTag.toString(),
          link: "/workflow-tag",
          active: false,
          icon: "side_tags.svg",
          breadCrumbs: [
          {
            title: RolePageAction.Admin.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.WorkflowTag.toString(),
            link: "/workflow-tag",
          }
          ],
            operations: [
                                        {
                                            name: RolePageAction.SaveTag.toString()
                                        },
                                        {
                                            name: RolePageAction.EditTag.toString()
                                        },
                                        {
                                            name: RolePageAction.DeleteTag.toString()
                                        }
                                    ]
          },
          //WorkflowActor
          {
          name:RolePageAction.WorkflowActor.toString(),
          link: "/workflow-actor",
          active: false,
          icon: "side_actors.svg",
          breadCrumbs: [
          {
            title: RolePageAction.Admin.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.WorkflowActor.toString(),
            link: "/workflow-actor",
          }
          ],
          operations: [
                                        {
                                            name: RolePageAction.SaveActor.toString()
                                        }
                                    ]
          },
          //WorkflowTask
          {
          name:RolePageAction.WorkflowTask.toString(),
          link: "/workflow-task",
          active: false,
          icon: "side_actors.svg",
          breadCrumbs: [
          {
            title: RolePageAction.Admin.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.WorkflowTask.toString(),
            link: "/workflow-task",
          }
          ]
          },
         
        ],
         url:"workflow",
         id:"workflow",
         disabled:false
        },
        //MessageConfig
        {
        groupName: RolePageAction.MessageConfig.toString(),
        pageMenu:[
           //MessageConfig 
          {
          name:RolePageAction.MessageConfig.toString(),
          link: "/message-setting",
          active: false,
          breadCrumbs: [
          {
            title: RolePageAction.Admin.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.MessageConfig.toString(),
            link: "/message-setting",
          }
          ],
               operations: [
                                        {
                                            name: RolePageAction.SaveMessageConfig.toString()
                                        },
                                        {
                                            name: RolePageAction.EditMessageConfig.toString()
                                        },
                                        {
                                            name: RolePageAction.DeleteMessageConfig.toString()
                                        }
                                    ]
          },
        ],
         url:"message-setting",
         id:"message-setting",
         disabled:false
        },
         //Identity
        {
        groupName: RolePageAction.Identity.toString(),
        pageMenu:[
          //IdentityGroup
          {
          name:RolePageAction.IdentityGroup.toString(),
          link: "/identity-group",
          active: false,
          icon: "side_identity_group.svg",
          breadCrumbs: [
          {
            title: RolePageAction.Admin.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.IdentityGroup.toString(),
            link: "/identity-group",
          }
          ]
          },
          //Identity
          {
          name:RolePageAction.Identity.toString(),
          link: "/identity",
          active: false,
          icon: "side_identity.svg",
          breadCrumbs: [
          {
            title: RolePageAction.Admin.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.Identity.toString(),
            link: "/identity",
          }
          ]
          },
          //AttributeSet 
          {
          name:RolePageAction.AttributeSet.toString(),
          link: "/attribute-set",
          active: false,
          icon: "side_attribute_set.svg",
          breadCrumbs: [
          {
            title: RolePageAction.Admin.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.AttributeSet.toString(),
            link: "/attribute-set",
          }
          ]
          },
            //Attribute
          {
          name:RolePageAction.Attribute.toString(),
          link: "/attribute",
          active: false,
          icon: "side_attribute.svg",
          breadCrumbs: [
          {
            title: RolePageAction.Admin.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.Attribute.toString(),
            link: "/attribute",
          }
          ]
          },
        ],
         url:"identity",
         id:"identity",
         disabled:false
        },
         //Consolidation
        {
          groupName: RolePageAction.Consolidation.toString(),
          pageMenu:[
             //Consolidation 
            {
            name:RolePageAction.Consolidation.toString(),
            link: "/consolidation",
            active: false,
            breadCrumbs: [
            {
              title: RolePageAction.Admin.toString(),
              link: "/view-all",
            },
            {
              title: RolePageAction.Consolidation.toString(),
              link: "/consolidation",
            }
            ]
            },
          ],
           url:"consolidation",
           id:"consolidation",
           disabled:false
          },
      ],
      reports:[
       
        {
           id:1,
           name:"Trial Balance",
           url:"trial-balance",
           extra: {},
           disabled: false
        }
      ],
      id:"admin",
      imageUrl: "/images/admin_img-icon.svg",
      disabled: false,
      color:"#099AD9"
    },
    // GL
    {
      module:RolePageAction.GL.toString(),
      pages:[
        //Chart Of Account
        {
        groupName: RolePageAction.ChartOfAccount.toString(),
        pageMenu:[
          //Account Class 
          {
          name:RolePageAction.AccountClass.toString(),
          link: "/account-class",
          active: false,
          icon: "side_accountclass.svg",
          breadCrumbs: [
          {
            title: RolePageAction.GL.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.AccountClass.toString(),
            link: "/account-class",
          }
          ],
                operations:
                                [
                                    {
                                        name: RolePageAction.SaveAccountClass.toString()
                                    },
                                    {
                                        name: RolePageAction.EditAccountClass.toString()
                                    },
                                    {
                                        name: RolePageAction.DeleteAccountClass.toString()
                                    },
                                    {
                                        name: RolePageAction.ExportAccountClass.toString()
                                    },
                                    {
                                        name: RolePageAction.ImportAccountClass
                                    }
                                ]
          },
          //Account Group 
          {
          name:RolePageAction.AccountGroup.toString(),
          link: "/account-group",
          active: false,
          icon: "side_accountgroup.svg",
          breadCrumbs: [
          {
            title: RolePageAction.GL.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.AccountGroup.toString(),
            link: "/account-group",
          }
          ],
               operations: [
                                {
                                    name: RolePageAction.SaveAccountGroup.toString()
                                },
                                {
                                    name: RolePageAction.EditAccountGroup.toString()
                                },
                                {
                                    name: RolePageAction.DeleteAccountGroup.toString()
                                },
                                {
                                    name: RolePageAction.ExportAccountGroup.toString()
                                },
                                {
                                    name: RolePageAction.ImportAccountGroup
                                }
                            ]
          
          },
          //Accounts 
          {
          name:RolePageAction.Accounts.toString(),
          link: "/accounts",
          active: false,
          icon: "side_accounts.svg",
          breadCrumbs: [
          {
            title: RolePageAction.GL.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.Accounts.toString(),
            link: "/accounts",
          }
          ],
                operations: [
                                {
                                    name: RolePageAction.SaveOperationAccount
                                },
                                {
                                    name: RolePageAction.EditOperationAccount
                                },
                                {
                                    name: RolePageAction.DeleteOperationAccount
                                },
                                {
                                    name: RolePageAction.ExportOperationAccount
                                },
                                {
                                    name: RolePageAction.ImportOperationAccount
                                },
                                {
                                    name: RolePageAction.AllocationRuleOperationAccount
                                },
                                {
                                    name: RolePageAction.SaveControlAccount
                                },
                                {
                                    name: RolePageAction.EditControlAccount
                                },
                                {
                                    name: RolePageAction.DeleteControlAccount
                                },
                                {
                                    name: RolePageAction.ExportControlAccount
                                },
                                {
                                    name: RolePageAction.ImportControlAccount
                                },
                            ]
          },
       
        ],
        url:"account-class",
         id:"account-class",
         disabled:false
        },
        //Journal
        {
        groupName: RolePageAction.Journal.toString(),
        pageMenu:[
          //journal Template
          {
            detail:false,
            name:RolePageAction.JournalTemplate.toString(),
            link: "/journal-template",
            active: false,
            icon: "side_journal_template.svg",
            breadCrumbs: [
            {
              title: RolePageAction.GL.toString(),
              link: "/view-all",
            },
            {
              title: RolePageAction.JournalTemplate.toString(),
              link: "/journal-template",
            }
            ],
               operations: [
                                {
                                    name: RolePageAction.SaveJournalTemplate.toString(),
                                },
                                {
                                    name: RolePageAction.EditJournalTemplate.toString(),
                                },
                                {
                                    name: RolePageAction.DeleteJournalTemplate.toString(),
                                }
                                ,
                                {
                                    name: RolePageAction.ImportJournalTemplate.toString(),
                                },
                                {
                                    name: RolePageAction.ExportJournalTemplate.toString(),
                                }
                            ]
          },
          //Journal List && Journal
          {
            children:[{name:RolePageAction.Journal.toString(),link:"/journal"}],
            name:RolePageAction.Journal.toString()+"s",
            link: "/journals",
            active: false,
            icon: "side_journal.svg",
            breadCrumbs: [
            {
              title: RolePageAction.GL.toString(),
              link: "/view-all",
            },
            {
              title: RolePageAction.Journal.toString()+"s",
              link: "/journals",
            }
            ],
             operations: [
                                {
                                    name: RolePageAction.SaveJournal.toString()
                                },
                                {
                                    name: RolePageAction.EditJournal.toString()
                                },
                                {
                                    name: RolePageAction.DeleteJournal.toString()
                                },
                                {
                                    name: RolePageAction.ImportJournal.toString()
                                }, {
                                    name: RolePageAction.ExportJournal.toString()
                                },
                            ]
          },
           //Opening Balance
          {
          children:[{name:RolePageAction.OpeningBalanceJournal.toString(),link:"/opening-balance-journal"}],
          name:RolePageAction.OpeningBalanceJournal.toString()+"s",
          link: "/opening-balance-journals",
          active: false,
          icon: "side_journal.svg",
          breadCrumbs: [
          {
            title: RolePageAction.GL.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.OpeningBalanceJournal.toString()+"s",
            link: "/opening-balance-journals",
          }
          ],
             operations: [
                                
                            ]
          },
          //AdjustmentJournals && AdjustmentJournal
          {
          children:[{name:RolePageAction.AdjustmentJournal.toString(),link:"/adjustment-journal"}],
          name:RolePageAction.AdjustmentJournal.toString()+"s",
          link: "/adjustment-journals",
          active: false,
          icon: "side_journal.svg",
          breadCrumbs: [
          {
            title: RolePageAction.GL.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.AdjustmentJournal.toString()+"s",
            link: "/adjustment-journals",
          }
          ],
             operations: [
                                
                            ]
          },
          //RecurringJournals && RecurringJournal
          {
          children:[{name:RolePageAction.RecurringJournal.toString(),link:"/recurring-journal"}], 
          name:RolePageAction.RecurringJournal.toString()+"s",
          link: "/recurring-journals",
          active: false,
          icon: "side_recurring_journal.svg",
          breadCrumbs: [
          {
            title: RolePageAction.GL.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.RecurringJournal.toString()+"s",
            link: "/recurring-journals",
          }
          ],
                operations: [
                                {
                                    name: RolePageAction.SaveRecurringJournal.toString()
                                },
                                {
                                    name: RolePageAction.EditRecurringJournal.toString()
                                },
                                {
                                    name: RolePageAction.DeleteRecurringJournal.toString()
                                },
                                {
                                    name: RolePageAction.ImportRecurringJournal.toString()
                                },
                                {
                                    name: RolePageAction.ExportRecurringJournal.toString()
                                }
                            ]
          },
        ],
        url:"journals",
        id:"journals",
         disabled:false
        },
        //Currency List
        {
        groupName: RolePageAction.CurrencyList.toString(),
        pageMenu:[
          {
            name:RolePageAction.CurrencyList.toString(),
            link: "/currency",
            active: false,
            breadCrumbs: [
            {
              title: RolePageAction.GL.toString(),
              link: "/view-all",
            },
            {
              title: RolePageAction.CurrencyList.toString(),
              link: "/currency",
            }
            ],
            operations:[
                   {
                                    name: RolePageAction.SaveCurrency
                                },
                                {
                                    name: RolePageAction.EditCurrency
                                },
                                {
                                    name: RolePageAction.DeleteCurrency
                                }
            ]
          },
          
        ],
        url:"currency",
        id:"currency",
         disabled:false
        },
         //Exchange Rate
        {
        groupName: RolePageAction.ExchangeRate.toString(),
        pageMenu:[
          {
            name:RolePageAction.ExchangeRate.toString(),
            link: "/exchange-rate",
            active: false,
            breadCrumbs: [
            {
              title: RolePageAction.GL.toString(),
              link: "/view-all",
            },
            {
              title: RolePageAction.ExchangeRate.toString(),
              link: "/exchange-rate",
            }
            ],
              operations: [
                                {
                                    name: RolePageAction.SaveExchangeRate
                                },
                                {
                                    name: RolePageAction.EditExchangeRate
                                },
                                {
                                    name: RolePageAction.DeleteExchangeRate
                                }
                            ]
          },
          
        ],
        url:"exchange-rate",
        id:"exchange-rate",
         disabled:false
        },
        //Inter company Journal
        {
        groupName: RolePageAction.InterCompanyJournal.toString(),
        pageMenu:[
          // Inter CompanyJournal  Template
          {
            detail:false,
            name:RolePageAction.InterCompanyJournalTemplate.toString(),
            link: "/intercompany-journal-template",
            active: false,
            icon: "inter-journal-template-icon.svg",
            breadCrumbs: [
            {
              title: RolePageAction.GL.toString(),
              link: "/view-all",
            },
            {
              title: RolePageAction.JournalTemplate.toString(),
              link: "/intercompany-journal-template",
            }
            ],
             operations: [
                                {
                                    name: RolePageAction.SaveInterCompanyJournalTemplate.toString()
                                },
                                {
                                    name: RolePageAction.EditInterCompanyJournalTemplate.toString()
                                },
                                {
                                    name: RolePageAction.DeleteInterCompanyJournalTemplate.toString(),
                                },
                                {
                                    name: RolePageAction.ImportInterCompanyJournalTemplate.toString()
                                },
                                {
                                    name: RolePageAction.ExportInterCompanyJournalTemplate.toString()
                                },
                            ]
          },
          //InterJournal List && Journal
          {
            children:[{name:RolePageAction.InterCompanyJournal.toString(),link:"/intercompany-journal"}],
            name:RolePageAction.InterCompanyJournal.toString()+"s",
            link: "/intercompany-journals",
            active: false,
            icon: "inter-journal-template-icon.svg",
            breadCrumbs: [
            {
              title: RolePageAction.GL.toString(),
              link: "/view-all",
            },
            {
              title: RolePageAction.InterCompanyJournal.toString()+"s",
              link: "/intercompany-journals",
            }
            ],
              operations: [
                                {
                                    name: RolePageAction.SaveInterCompanyJournal.toString(),
                                },
                                {
                                    name: RolePageAction.EditInterCompanyJournal.toString(),
                                },
                                {
                                    name: RolePageAction.DeleteInterCompanyJournal.toString()
                                },
                                {
                                    name: RolePageAction.ImportInterCompanyJournal.toString()
                                },
                                {
                                    name: RolePageAction.ExportInterCompanyJournal.toString(),
                                }
                            ]
          }
        ],
        url:"intercompany-journals",
        id:"intercompany-journals",
         disabled:false
        },
        //FiscalYear
        {
        groupName: RolePageAction.FiscalYear.toString(),
        pageMenu:[
          {
            children:[{name:RolePageAction.AccountingPeriod.toString(),link:"/accounting-period"}],
            name:RolePageAction.FiscalYear.toString(),
            link: "/fiscal-year",
            active: false,
            breadCrumbs: [
            {
              title: RolePageAction.GL.toString(),
              link: "/view-all",
            },
            {
              title: RolePageAction.FiscalYear.toString(),
              link: "/fiscal-year",
            }
            ],
              operations: [
                                {
                                    name: RolePageAction.SaveFiscalYear.toString()
                                },
                                {
                                    name: RolePageAction.EditFiscalYear.toString()
                                },
                                {
                                    name: RolePageAction.DeleteFiscalYear.toString()
                                },
                                {
                                    name: RolePageAction.ImportFiscalYear.toString()
                                },
                                {
                                    name: RolePageAction.ExportFiscalYear.toString()
                                }
                            ]
          },
          
        ],
        url:"fiscal-year",
        id:"fiscal-year",
         disabled:false
        },
        //TrialBalance
        {
        groupName: RolePageAction.TrialBalance.toString(),
        pageMenu:[
          {
            children:[{name:RolePageAction.TransactionHistory.toString(),link:"/transaction-history"}],
            name:RolePageAction.TrialBalance.toString(),
            link: "/trial-balance",
            active: false,
            breadCrumbs: [
            {
              title: RolePageAction.GL.toString(),
              link: "/view-all",
            },
            {
              title: RolePageAction.TrialBalance.toString(),
              link: "/trial-balance",
            }
            ],
              operations: [
                                {
                                    name: RolePageAction.GenarateTrialBalanceReport.toString()
                                },
                                {
                                    name: RolePageAction.ImportTrialBalanceReport.toString()
                                },
                                {
                                    name: RolePageAction.ExportTrialBalanceReport.toString()
                                }
                            ]
          },
        ],
        url:"trial-balance",
        id:"trial-balance",
         disabled:false
        },
        //Tax
        {
        groupName: RolePageAction.Tax.toString(),
        pageMenu:[
          {
            name:RolePageAction.TrialBalance.toString(),
            link: "/tax",
            active: false,
            breadCrumbs: [
            {
              title: RolePageAction.GL.toString(),
              link: "/view-all",
            },
            {
              title: RolePageAction.Tax.toString(),
              link: "/tax",
            }
            ]
          },
        ],
        url:"tax",
        id:"tax",
         disabled:false
        },
       
        //ModuleManagement
        {
        groupName: RolePageAction.ModuleManagement.toString(),
        pageMenu:[
          //ModuleConfig
          {
          name:RolePageAction.ModuleConfig.toString(),
          link: "/module-config",
          active: false,
          icon: "side_module_config.svg",
          breadCrumbs: [
          {
            title: RolePageAction.GL.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.ModuleConfig.toString(),
            link: "/module-config",
          }
          ]
          },
          //ControlGLink
          {
          name:RolePageAction.ControlGLink.toString(),
          link: "/ledger-header",
          active: false,
          icon: "side_ledger_header.svg",
          breadCrumbs: [
          {
            title: RolePageAction.GL.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.ControlGLink.toString(),
            link: "/ledger-header",
          }
          ]
          },
          //Subsidiary 
          {
          name:RolePageAction.Subsidiary.toString(),
          link: "/subsidiary",
          active: false,
          icon: "side_subsidiary.svg",
          breadCrumbs: [
          {
            title: RolePageAction.GL.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.Subsidiary.toString(),
            link: "/subsidiary",
          }
          ]
          },
            //Operation
          {
          name:RolePageAction.Operation.toString(),
          link: "/operation",
          active: false,
          icon: "side_operation.svg",
          breadCrumbs: [
          {
            title: RolePageAction.GL.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.Operation.toString(),
            link: "/operation",
          }
          ]
          },
        ],
        url:"module-config",
         id:"module-config",
         disabled:false
        },
      ],
      reports:[
       
        {
           id:1,
           name:"Trial Balance",
           url:"trial-balance",
           extra: {},
           disabled: false
        }
      ],
      id:"gl",
      imageUrl: "/images/general_ledger_img-icon.svg",
      disabled: false,
      color:"#E69EBA"

    },
    //AP
    {
      module:RolePageAction.AP.toString(),
        pages:[
        //Vendor
        {
        groupName: RolePageAction.Vendor.toString(),
        pageMenu:[
            //VendorGroup 
          {
          name:RolePageAction.VendorGroup.toString(),
          link: "/vendor-group",
          active: false,
          icon: "side_vendor_group.svg",
          breadCrumbs: [
          {
            title: RolePageAction.AP.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.VendorGroup.toString(),
            link: "/vendor-group",
          }
          ]
          },
          //Vendor 
          {
          name:RolePageAction.Vendor.toString(),
          link: "/vendor",
          active: false,
          icon: "side_vendors.svg",
          breadCrumbs: [
          {
            title: RolePageAction.AP.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.Vendor.toString(),
            link: "/vendor",
          }
          ]
          },
         //VendorPaymentTerm 
          {
          name:RolePageAction.VendorPaymentTerm.toString(),
          link: "/vendor-payment-term",
          active: false,
          icon: "side_payment_term.svg",
          breadCrumbs: [
          {
            title: RolePageAction.AP.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.VendorPaymentTerm.toString(),
            link: "/vendor-payment-term",
          }
          ]
          },
          //VendorPayment
          {
          children:[{name:RolePageAction.VendorPayment.toString(),link:"/vendor-payment"}],
          name:RolePageAction.VendorPayment.toString()+"s",
          link: "/vendor-payments",
          active: false,
          icon: "side_payment.svg",
          breadCrumbs: [
          {
            title: RolePageAction.AP.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.VendorPayment.toString()+"s",
            link: "/vendor-payments",
          }
          ]
          },
          //VendorInvoice 
          {
         children:[{name:RolePageAction.VendorInvoice.toString(),link:"/vendor-invoice"}],
          name:RolePageAction.VendorInvoice.toString()+"s",
          link: "/vendor-invoices",
          active: false,
          icon: "side_invoice.svg",
          breadCrumbs: [
          {
            title: RolePageAction.AP.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.VendorInvoice.toString()+"s",
            link: "/vendor-invoices",
          }
          ]
          },
        ],
         url:"vendor",
         id:"vendor",
         disabled:false
        },
        //VendorPaymentSchedule
        {
        groupName: RolePageAction.VendorPaymentSchedule.toString(),
        pageMenu:[
           //VendorPaymentSchedule 
          {
          children:[{name:RolePageAction.VendorPaymentSchedule.toString(),link:"/vendor-payment-schedule"}],
          name:RolePageAction.VendorPaymentSchedule.toString()+"s",
          link: "/vendor-payment-schedules",
          active: false,
          icon: "side_role.svg",
          breadCrumbs: [
          {
            title: RolePageAction.AP.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.VendorPaymentSchedule.toString()+"s",
            link: "/vendor-payment-schedules",
          }
          ]
          },
        ],
         url:"vendor-payment-schedules",
         id:"vendor-payment-schedules",
         disabled:false
        },
        //VendorRecurringPayment
        {
        groupName: RolePageAction.VendorRecurringPayment.toString(),
        pageMenu:[
           //VendorPaymentSchedule 
          {
          children:[{name:RolePageAction.VendorRecurringPayment.toString(),link:"/vendor-recurring-payment"}],
          name:RolePageAction.VendorRecurringPayment.toString()+"s",
          link: "/vendor-recurring-payments",
          active: false,
          icon: "side_role.svg",
          breadCrumbs: [
          {
            title: RolePageAction.AP.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.VendorRecurringPayment.toString()+"s",
            link: "/vendor-recurring-payments",
          }
          ]
          },
        ],
         url:"vendor-recurring-payments",
         id:"vendor-recurring-payments",
         disabled:false
        },
        //VendorInvoice
        {
        groupName: RolePageAction.Vendor,
        name: RolePageAction.VendorInvoice,
        pageMenu:[
          //VendorInvoice 
          {
          children:[{name:RolePageAction.VendorInvoice.toString(),link:"/vendor-invoice"}],
          name:RolePageAction.VendorInvoice.toString()+"s",
          link: "/vendor-invoices",
          active: false,
          icon: "side_invoice.svg",
          breadCrumbs: [
          {
            title: RolePageAction.AP.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.VendorInvoice.toString()+"s",
            link: "/vendor-invoices",
          }
          ]
          },
        ],
         url:"vendor-invoices",
         id:"vendor-invoices",
         disabled:false
        },
      ],
      reports:[

      ],
      id:"ap",
      imageUrl: "/images/account_payable_img-icon.svg",
      disabled: false,
      color:"#d43e11"
    },
     //AR
    {
      module:RolePageAction.AR.toString(),
      pages:[
        //Customer
        {
        groupName: RolePageAction.Customer.toString(),
        pageMenu:[
           //CustomerGroup 
          {
          name:RolePageAction.CustomerGroup.toString(),
          link: "/customer-group",
          active: false,
          icon: "side_customer_group.svg",
          breadCrumbs: [
          {
            title: RolePageAction.AR.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.CustomerGroup.toString(),
            link: "/customer-group",
          }
          ]
          },
          //Customer 
          {
          name:RolePageAction.Customer.toString(),
          link: "/customer",
          active: false,
          icon: "side_customers.svg",
          breadCrumbs: [
          {
            title: RolePageAction.AR.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.Customer.toString(),
            link: "/customer",
          }
          ]
          },
         //Customer PaymentTerm 
          {
          name:RolePageAction.CustomerPaymentTerm.toString(),
          link: "/customer-payment-term",
          active: false,
          icon: "side_payment_term.svg",
          breadCrumbs: [
          {
            title: RolePageAction.AR.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.CustomerPaymentTerm.toString(),
            link: "/customer-payment-term",
          }
          ]
          },
          //CustomerPayment
          {
          children:[{name:RolePageAction.CustomerPayment.toString(),link:"/customer-payment"}],
          name:RolePageAction.CustomerPayment.toString()+"s",
          link: "/customer-payments",
          active: false,
          icon: "side_payment.svg",
          breadCrumbs: [
          {
            title: RolePageAction.AR.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.VendorPayment.toString()+"s",
            link: "/customer-payments",
          }
          ]
          },
          //Customer Invoice 
          {
         children:[{name:RolePageAction.CustomerInvoice.toString(),link:"/customer-invoice"}],
          name:RolePageAction.CustomerInvoice.toString()+"s",
          link: "/customer-invoices",
          active: false,
          icon: "side_invoice.svg",
          breadCrumbs: [
          {
            title: RolePageAction.AR.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.CustomerInvoice.toString()+"s",
            link: "/vendor-invoices",
          }
          ]
          },
        ],
         url:"customer",
         id:"customer",
         disabled:false
        },
        //Customer Invoice
        {
        groupName: RolePageAction.Customer,
        name: RolePageAction.CustomerInvoice,
        pageMenu:[
          //CustomerInvoice 
          {
          children:[{name:RolePageAction.CustomerInvoice.toString(),link:"/customer-invoice"}],
          name:RolePageAction.CustomerInvoice.toString()+"s",
          link: "/customer-invoices",
          active: false,
          icon: "side_invoice.svg",
          breadCrumbs: [
          {
            title: RolePageAction.AR.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.CustomerInvoice.toString()+"s",
            link: "/customer-invoices",
          }
          ]
          },
        ],
         url:"customer-invoices",
         id:"customer-invoices",
         disabled:false
        },
        //CustomerPaymentSchedule
        {
        groupName: RolePageAction.CustomerPaymentSchedule.toString(),
        pageMenu:[
           //CustomerPaymentSchedule 
          {
          children:[{name:RolePageAction.CustomerPaymentSchedule.toString(),link:"/customer-payment-schedule"}],
          name:RolePageAction.CustomerPaymentSchedule.toString()+"s",
          link: "/customer-payment-schedules",
          active: false,
          icon: "side_role.svg",
          breadCrumbs: [
          {
            title: RolePageAction.AR.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.CustomerPaymentSchedule.toString()+"s",
            link: "/customer-payment-schedules",
          }
          ]
          },
        ],
         url:"customer-payment-schedules",
         id:"customer-payment-schedules",
         disabled:false
        },
        //CustomerRecurringPayment
        {
        groupName: RolePageAction.CustomerRecurringPayment.toString(),
        pageMenu:[
           //VendorPaymentSchedule 
          {
          children:[{name:RolePageAction.CustomerRecurringPayment.toString(),link:"/customer-recurring-payment"}],
          name:RolePageAction.CustomerRecurringPayment.toString()+"s",
          link: "/customer-recurring-payments",
          active: false,
          icon: "side_role.svg",
          breadCrumbs: [
          {
            title: RolePageAction.AR.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.CustomerRecurringPayment.toString()+"s",
            link: "/customer-recurring-payments",
          }
          ]
          },
        ],
         url:"customer-recurring-payments",
         id:"customer-recurring-payments",
         disabled:false
        },
        //FailedPayment
        {
        groupName: RolePageAction.FailedPayment.toString(),
        pageMenu:[
           //FailedPayment 
          {
          name:RolePageAction.FailedPayment.toString(),
          link: "/failed-payment",
          active: false,
          icon: "side_role.svg",
          breadCrumbs: [
          {
            title: RolePageAction.AR.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.FailedPayment.toString(),
            link: "/failed-payment",
          }
          ]
          },
        ],
         url:"failed-payment",
         id:"failed-payment",
         disabled:false
        },
        
      ],
      reports:[

      ],
      id:"gl",
      imageUrl: "/images/account_receivable_img-icon.svg",
      disabled: false,
      color:"#11d499"
    },
     //Fixed Asset
    {
      module:RolePageAction.FA.toString(),
        pages:[
        //Asset
        {
        groupName: RolePageAction.Asset.toString(),
        pageMenu:[
          //AssetClass 
          {
          name:RolePageAction.AssetClass.toString(),
          link: "/asset-class",
          active: false,
          icon: "side_asset_class.svg",
          breadCrumbs: [
          {
            title: RolePageAction.FA.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.AssetClass.toString(),
            link: "/asset-class",
          }
          ]
          },
          //Asset
          {
          children:[{name:RolePageAction.Asset.toString(),link:"/asset"}],
          name:RolePageAction.Asset.toString()+"s",
          link: "/assets",
          active: false,
          icon: "side_assets.svg",
          breadCrumbs: [
          {
            title: RolePageAction.FA.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.Asset.toString()+"s",
            link: "/assets",
          }
          ]
          },
          //RecurringDepreciation 
            {
            name:RolePageAction.RecurringDepreciation.toString(),
            link: "/recurring-depreciation",
            active: false,
            icon: "side_role.svg",
            breadCrumbs: [
            {
              title: RolePageAction.FA.toString(),
              link: "/view-all",
            },
            {
              title: RolePageAction.RecurringDepreciation.toString(),
              link: "/recurring-depreciation",
            }
            ]
            },
        
        ],
         url:"assets",
         id:"assets",
         disabled:false
        },
        //MaintenanceSchedule
        {
        groupName: RolePageAction.MaintenanceSchedule.toString(),
        pageMenu:[
           //MaintenanceSchedule 
          {
          children:[{name:RolePageAction.MaintenanceSchedule.toString(),link:"/maintenance-schedule"}],
          name:RolePageAction.MaintenanceSchedule.toString()+"s",
          link: "/maintenance-schedules",
          active: false,
          icon: "side_role.svg",
          breadCrumbs: [
          {
            title: RolePageAction.FA.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.MaintenanceSchedule.toString()+"s",
            link: "/maintenance-schedules",
          }
          ]
          },
        ],
         url:"maintenance-schedules",
         id:"maintenance-schedules",
         disabled:false
        },
        //DepreciationSchedule
        {
        groupName: RolePageAction.DepreciationSchedule.toString(),
        pageMenu:[
           //MaintenanceSchedule 
          {
          children:[{name:RolePageAction.DepreciationSchedule.toString(),link:"/depreciation-schedule"}],
          name:RolePageAction.DepreciationSchedule.toString()+"s",
          link: "/depreciation-schedules",
          active: false,
          icon: "side_role.svg",
          breadCrumbs: [
          {
            title: RolePageAction.FA.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.DepreciationSchedule.toString()+"s",
            link: "/depreciation-schedules",
          }
          ]
          },
        ],
         url:"depreciation-schedules",
         id:"depreciation-schedules",
         disabled:false
        },
        //StockTaking
        {
        groupName: RolePageAction.StockTaking.toString(),
        pageMenu:[
           //MaintenanceSchedule 
          {
          children:[{name:RolePageAction.StockTaking.toString(),link:"/asset-stock"}],
          name:RolePageAction.StockTaking.toString()+"s",
          link: "/asset-stocks",
          active: false,
          icon: "side_role.svg",
          breadCrumbs: [
          {
            title: RolePageAction.FA.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.StockTaking.toString()+"s",
            link: "/asset-stocks",
          }
          ]
          },
        ],
         url:"asset-stocks",
         id:"asset-stocks",
         disabled:false
        },
        //InsuranceSchedule
        {
        groupName: RolePageAction.InsuranceSchedule.toString(),
        pageMenu:[
           //InsuranceSchedule 
          {
          children:[{name:RolePageAction.InsuranceSchedule.toString(),link:"insurance-schedule"}],
          name:RolePageAction.InsuranceSchedule.toString()+"s",
          link: "/insurance-schedules",
          active: false,
          icon: "side_role.svg",
          breadCrumbs: [
          {
            title: RolePageAction.FA.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.InsuranceSchedule.toString()+"s",
            link: "/insurance-schedules",
          }
          ]
          },
        ],
         url:"insurance-schedules",
         id:"insurance-schedules",
         disabled:false
        },
          //RevaluationSchedule
        {
        groupName: RolePageAction.RevaluationSchedule.toString(),
        pageMenu:[
           //RevaluationSchedule 
          {
          children:[{name:RolePageAction.RevaluationSchedule.toString(),link:"revaluation-schedule"}],
          name:RolePageAction.RevaluationSchedule.toString()+"s",
          link: "/revaluation-schedules",
          active: false,
          icon: "side_role.svg",
          breadCrumbs: [
          {
            title: RolePageAction.FA.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.RevaluationSchedule.toString()+"s",
            link: "/revaluation-schedules",
          }
          ]
          },
        ],
         url:"revaluation-schedules",
         id:"revaluation-schedules",
         disabled:false
        },
        //DisposalSchedule
        {
        groupName: RolePageAction.DisposalSchedule.toString(),
        pageMenu:[
          //DisposalSchedule 
          {
          children:[{name:RolePageAction.DisposalSchedule.toString(),link:"disposal-schedule"}],
          name:RolePageAction.DisposalSchedule.toString()+"s",
          link: "/disposal-schedules",
          active: false,
          icon: "side_role.svg",
          breadCrumbs: [
          {
            title: RolePageAction.FA.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.DisposalSchedule.toString()+"s",
            link: "/disposal-schedules",
          }
          ]
          },
        ],
         url:"disposal-schedules",
         id:"disposal-schedules",
         disabled:false
        },
        //SplittingSchedule
        {
        groupName: RolePageAction.SplittingSchedule.toString(),
        pageMenu:[
          //SplittingSchedule 
          {
          children:[{name:RolePageAction.SplittingSchedule.toString(),link:"splitting-schedule"}],
          name:RolePageAction.SplittingSchedule.toString()+"s",
          link: "/splitting-schedules",
          active: false,
          icon: "side_role.svg",
          breadCrumbs: [
          {
            title: RolePageAction.FA.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.SplittingSchedule.toString()+"s",
            link: "/splitting-schedules",
          }
          ]
          },
        ],
         url:"splitting-schedules",
         id:"splitting-schedules",
         disabled:false
        },
        //EnhancementSchedule
        // {
        // groupName: RolePageAction.EnhancementSchedule.toString(),
        // pageMenu:[
        //   //EnhancementSchedule 
        //   {
        //   children:[{name:RolePageAction.EnhancementSchedule.toString(),link:"enhancement-schedule"}],
        //   name:RolePageAction.EnhancementSchedule.toString()+"s",
        //   link: "/enhancement-schedules",
        //   active: false,
        //   icon: "side_role.svg",
        //   breadCrumbs: [
        //   {
        //     title: RolePageAction.FA.toString(),
        //     link: "/view-all",
        //   },
        //   {
        //     title: RolePageAction.EnhancementSchedule.toString()+"s",
        //     link: "/enhancement-schedules",
        //   }
        //   ]
        //   },
        // ],
        //  url:"enhancement-schedules",
        //  id:"enhancement-schedules",
        //  disabled:true
        // },
        //MovementSchedule
        // {
        // groupName: RolePageAction.MovementSchedule.toString(),
        // pageMenu:[
        //   //MovementSchedule 
        //   {
        //   children:[{name:RolePageAction.MovementSchedule.toString(),link:"movement-schedule"}],
        //   name:RolePageAction.MovementSchedule.toString()+"s",
        //   link: "/movement-schedules",
        //   active: false,
        //   icon: "side_role.svg",
        //   breadCrumbs: [
        //   {
        //     title: RolePageAction.FA.toString(),
        //     link: "/view-all",
        //   },
        //   {
        //     title: RolePageAction.MovementSchedule.toString()+"s",
        //     link: "/movement-schedules",
        //   }
        //   ]
        //   },
        // ],
        //  url:"movement-schedules",
        //  id:"movement-schedules",
        //  disabled:true
        // },
         //InsuranceCompany
        {
        groupName: RolePageAction.InsuranceCompany.toString(),
        pageMenu:[
          //InsuranceCompany 
          {
          name:RolePageAction.InsuranceCompany.toString(),
          link: "/insurance-company",
          active: false,
          icon: "side_role.svg",
          breadCrumbs: [
          {
            title: RolePageAction.FA.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.InsuranceCompany.toString(),
            link: "/insurance-company",
          }
          ]
          },
        ],
         url:"insurance-company",
         id:"insurance-company",
         disabled:false
        },
      ],
      reports:[
        {
           id:1,
           name:"Trial Balance",
           url:"trial-balance",
           extra: {},
           disabled: false
        }
      ],
      id:"gl",
      imageUrl: "/images/asset_accounting_img-icon.svg",
      disabled: false,
      color:"#d4d011"
    },
    //Cash
    {
      module:RolePageAction.CM.toString(),
        pages:[
        //Bank
        {
        groupName: RolePageAction.Bank.toString(),
        pageMenu:[
          //Bank Group
          {
          name:RolePageAction.BankGroup.toString(),
          link: "/bank-group",
          active: false,
          icon: "side_bank_group.svg",
          breadCrumbs: [
          {
            title: RolePageAction.CM.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.BankGroup.toString(),
            link: "/bank-group",
          }
          ]
          },
          //Bank 
          {
          name:RolePageAction.Bank.toString(),
          link: "/bank",
          active: false,
          icon: "side_bank.svg",
          breadCrumbs: [
          {
            title: RolePageAction.CM.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.Bank.toString(),
            link: "/bank",
          }
          ]
          },
        ],
         url:"bank",
         id:"bank",
         disabled:false
        },
         //BankReconciliation
        {
        groupName: RolePageAction.BankReconciliation.toString(),
        pageMenu:[
           //RolePermission 
          {
             children:[
            {
              name:RolePageAction.BankReconciliation.toString(),
              link:"/bank-reconciliation"
            }
          ],
          name:RolePageAction.BankReconciliation.toString()+"s",
          link: "/bank-reconciliations",
          active: false,
          icon: "side_role.svg",
          breadCrumbs: [
          {
            title: RolePageAction.CM.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.BankReconciliation.toString()+"s",
            link: "/bank-reconciliations",
          }
          ]
          },
        ],
         url:"bank-reconciliations",
         id:"bank-reconciliation",
         disabled:false
        },
        //CashflowForecast
        {
        groupName: RolePageAction.CashflowForecast.toString(),
        pageMenu:[
          //Bank Group
          {
          name:RolePageAction.CashflowForecast.toString(),
          link: "/cashflow-forecasts",
          active: false,
          icon: "side_bank_group.svg",
          breadCrumbs: [
          {
            title: RolePageAction.CM.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.CashflowForecast.toString(),
            link: "/cashflow-forecasts",
          }
          ]
          },
        ],
         url:"cashflow-forecasts",
         id:"cashflow-forecasts",
         disabled:false
        },
        //FundTransfer
        {
        groupName: RolePageAction.FundTransfer.toString(),
        pageMenu:[
          //FundTransfer
          {
          children:[
            {
              name:RolePageAction.FundTransfer.toString(),
              link:"/fund-transfer"
            }
          ],
          name:RolePageAction.FundTransfer.toString()+"s",
          link: "/fund-transfers",
          active: false,
          icon: "side_bank_group.svg",
          breadCrumbs: [
          {
            title: RolePageAction.CM.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.FundTransfer.toString()+"s",
            link: "/fund-transfers",
          }
          ]
          },
        ],
        url:"fund-transfers",
        id:"fund-transfers",
        disabled:false
        },
      ],
      reports:[

      ],
      id:"cm",
      imageUrl: "/images/cash_management_img-icon.svg",
      disabled: false,
      color:"#8d11d4"
    },
    //Payroll
    {
      module:RolePageAction.PR.toString(),
        pages:[
        //Staff
        {
        groupName: RolePageAction.Staff.toString(),
        pageMenu:[
          //Department 
          {
          name:RolePageAction.Department.toString(),
          link: "/staff-department",
          active: false,
          icon: "side_staff_department.svg",
          breadCrumbs: [
          {
            title: RolePageAction.PR.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.Department.toString(),
            link: "/staff-department",
          }
          ]
          },
          //StaffUnit
          {
          name:RolePageAction.StaffUnit.toString(),
          link: "/staff-unit",
          active: false,
          icon: "side_staff_unit.svg",
          breadCrumbs: [
          {
            title: RolePageAction.PR.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.StaffUnit.toString(),
            link: "/staff-unit",
          }
          ]
          },
          //Staff
          {
          name:RolePageAction.Staff.toString(),
          link: "/staff",
          active: false,
          icon: "side_staff.svg",
          breadCrumbs: [
          {
            title: RolePageAction.PR.toString(),
            link: "/view-all",
          },
          //Staff
          {
            title: RolePageAction.Staff.toString(),
            link: "/staff",
          }
          ]
          },
          //StaffAllowance
          {
          name:RolePageAction.StaffAllowance.toString(),
          link: "/staff-allowance",
          active: false,
          icon: "side_staff_allowance.svg",
          breadCrumbs: [
          {
            title: RolePageAction.PR.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.StaffAllowance.toString(),
            link: "/staff-allowance",
          }
          ]
          },
          //StaffDeduction
          {
          name:RolePageAction.StaffDeduction.toString(),
          link: "/staff-deduction",
          active: false,
          icon: "side_staff_deduction.svg",
          breadCrumbs: [
          {
            title: RolePageAction.PR.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.StaffDeduction.toString(),
            link: "/staff-deduction",
          }
          ]
          },
        ],
         url:"staff",
         id:"staff",
         disabled:false
        },
        //Salary
        {
        groupName: RolePageAction.Salary.toString(),
        pageMenu:[
          //SalaryScale 
          {
          name:RolePageAction.SalaryScale.toString(),
          link: "/salary-scale",
          active: false,
          icon: "side_salary_scale.svg",
          breadCrumbs: [
          {
            title: RolePageAction.PR.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.SalaryScale.toString(),
            link: "/salary-scale",
          }
          ]
          },
          //GradeLevel
          {
          name:RolePageAction.GradeLevel.toString(),
          link: "/grade-level",
          active: false,
          icon: "side_grade_level.svg",
          breadCrumbs: [
          {
            title: RolePageAction.PR.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.GradeLevel.toString(),
            link: "/grade-level",
          }
          ]
          },
          //Step
          {
          name:RolePageAction.Step.toString(),
          link: "/salary-step",
          active: false,
          icon: "side_step.svg",
          breadCrumbs: [
          {
            title: RolePageAction.PR.toString(),
            link: "/view-all",
          },
          //Staff
          {
            title: RolePageAction.Step.toString(),
            link: "/salary-step",
          }
          ]
          },
          //Allowance
          {
          name:RolePageAction.Allowance.toString(),
          link: "/salary-allowance",
          active: false,
          icon: "side_allowance.svg",
          breadCrumbs: [
          {
            title: RolePageAction.PR.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.Allowance.toString(),
            link: "/salary-allowance",
          }
          ]
          },
          //Deduction
          {
          name:RolePageAction.Deduction.toString(),
          link: "/salary-deduction",
          active: false,
          icon: "side_deduction.svg",
          breadCrumbs: [
          {
            title: RolePageAction.PR.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.Deduction.toString(),
            link: "/salary-deduction",
          }
          ]
          },
          //PaymentDescription
          {
          name:RolePageAction.PaymentDescription.toString(),
          link: "/payment-description",
          active: false,
          icon: "side_payment_description.svg",
          breadCrumbs: [
          {
            title: RolePageAction.PR.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.PaymentDescription.toString(),
            link: "/payment-description",
          }
          ]
          },
        ],
         url:"salary-scale",
         id:"salary-scale",
         disabled:false
        },
         //SalarySchedule
        {
        groupName: RolePageAction.SalarySchedule.toString(),
        pageMenu:[
          //SalarySchedule
          {
          children:[{name:RolePageAction.SalarySchedule.toString(),link:"/salary-schedule"}],
          name:RolePageAction.SalarySchedule.toString()+"s",
          link: "/salary-schedules",
          active: false,
          icon: "side_allowance.svg",
          breadCrumbs: [
          {
            title: RolePageAction.PR.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.SalarySchedule.toString()+"s",
            link: "/salary-schedules",
          }
          ]
          }
        ],
         url:"salary-schedules",
         id:"salary-schedules",
         disabled:false
        },
     
      ],
      reports:[

      ],
      id:"ap",
      imageUrl: "/images/payroll_img-icon.svg",
      disabled: false,
      color:"#d48311"
    },
    //Inventory
    {
      module:RolePageAction.INV.toString(),
        pages:[
        //Product 
        {
        groupName: RolePageAction.Product.toString(),
        pageMenu:[
          //ProductGroup 
          {
          name:RolePageAction.ProductGroup.toString(),
          link: "/product-group",
          active: false,
          icon: "side_product_group.svg",
          breadCrumbs: [
          {
            title: RolePageAction.INV.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.ProductGroup.toString(),
            link: "/product-group",
          }
          ]
          },
          //Product
          {
          name:RolePageAction.Product.toString(),
          link: "/product",
          active: false,
          icon: "side_product.svg",
          breadCrumbs: [
          {
            title: RolePageAction.INV.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.Product.toString(),
            link: "/product",
          }
          ]
          },
          //Stock
          {
          name:RolePageAction.Stock.toString(),
          link: "/stock",
          active: false,
          icon: "side_stock.svg",
          breadCrumbs: [
          {
            title: RolePageAction.INV.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.Stock.toString(),
            link: "/stock",
          }
          ]
          },
          //StockMovement
          {
          name:RolePageAction.StockMovement.toString(),
          link: "/stock-movement",
          active: false,
          icon: "side_stock_management.svg",
          breadCrumbs: [
          {
            title: RolePageAction.INV.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.StockMovement.toString(),
            link: "/stock-movement",
          }
          ]
          },
          //StockTransfer
          {
           children:[{name:RolePageAction.StockTransfer.toString(),link:"/stock-transfer"}],
          name:RolePageAction.StockTransfer.toString()+"s",
          link: "/stock-transfers",
          active: false,
          icon: "side_stock_transfer.svg",
          breadCrumbs: [
          {
            title: RolePageAction.INV.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.StockTransfer.toString()+"s",
            link: "/stock-transfers",
          }
          ]
          },

        ],
         url:"product",
         id:"product",
         disabled:false
        },
        //Warehouse 
        {
        groupName: RolePageAction.Warehouse.toString(),
        pageMenu:[
          //Site 
          {
          name:RolePageAction.Site.toString(),
          link: "/site",
          active: false,
          icon: "side_site.svg",
          breadCrumbs: [
          {
            title: RolePageAction.INV.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.Site.toString(),
            link: "/site",
          }
          ]
          },
         //Warehouse 
          {
          name:RolePageAction.Warehouse.toString(),
          link: "/warehouse",
          active: false,
          icon: "side_warehouse.svg",
          breadCrumbs: [
          {
            title: RolePageAction.INV.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.Warehouse.toString(),
            link: "/warehouse",
          }
          ]
          },
          //Location 
          {
          name:RolePageAction.Location.toString(),
          link: "/location",
          active: false,
          icon: "side_location.svg",
          breadCrumbs: [
          {
            title: RolePageAction.INV.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.Location.toString(),
            link: "/location",
          }
          ]
          },
        ],
         url:"warehouse",
         id:"warehouse",
         disabled:false
        },
        //Stock 
        {
        name:RolePageAction.Stock.toString(),
        groupName: RolePageAction.Product.toString(),
        pageMenu:[

          //Stock
          {
          name:RolePageAction.Stock.toString(),
          link: "/stock",
          active: false,
          icon: "side_stock.svg",
          breadCrumbs: [
          {
            title: RolePageAction.INV.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.Stock.toString(),
            link: "/stock",
          }
          ]
          },
        ],
         url:"stock",
         id:"stock",
         disabled:false
        },
        // StockMovement
        {
        name:RolePageAction.StockMovement.toString(),
        groupName: RolePageAction.Product.toString(),
        pageMenu:[
          //StockMovement
          {
          name:RolePageAction.StockMovement.toString(),
          link: "/stock-movement",
          active: false,
          icon: "side_stock_management.svg",
          breadCrumbs: [
          {
            title: RolePageAction.INV.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.StockMovement.toString(),
            link: "/stock-movement",
          }
          ]
          },
        ],
         url:"stock-movement",
         id:"stock-movement",
         disabled:false
        },
        //ProductGroup
        {
        name:RolePageAction.ProductGroup.toString(),
        groupName: RolePageAction.Product.toString(),
        pageMenu:[
          //ProductGroup 
          {
          name:RolePageAction.ProductGroup.toString(),
          link: "/product-group",
          active: false,
          icon: "side_product_group.svg",
          breadCrumbs: [
          {
            title: RolePageAction.INV.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.ProductGroup.toString(),
            link: "/product-group",
          }
          ]
          },
        ],
         url:"product-group",
         id:"product-group",
         disabled:false
        },
        //StockTransfer 
        {
         childdren:[{name:RolePageAction.StockTransfer.toString(),link:"/stock-transfer"}],
        name:RolePageAction.StockTransfer.toString(),
        groupName: RolePageAction.Product.toString(),
        pageMenu:[
          //StockTransfer
          {
          name:RolePageAction.StockTransfer.toString()+"s",
          link: "/stock-transfers",
          active: false,
          icon: "side_stock_transfer.svg",
          breadCrumbs: [
          {
            title: RolePageAction.INV.toString(),
            link: "/view-all",
          },
          {
            title: RolePageAction.StockTransfer.toString()+"s",
            link: "/stock-transfers",
          }
          ]
          },
        ],
         url:"stock-transfers",
         id:"stock-transfers",
         disabled:false
        },
      ],
      reports:[

      ],
      id:"inv",
      imageUrl: "/images/inventory_img-icon.svg",
      disabled: false,
      color:"#d411cd"

    },
    

    ] as MenuServiceGroup[]
    data.forEach(f=>{
      f.pages.forEach(f1=>{
        f1.module=f.module;
        f1.pageMenu.forEach(pg=>{
          if(!pg.operations){
            pg.operations=[];
          }
        })
      });
    })
    const result = new PageMenuBreadCrumb(data);
    const found= data.find(f=>f.module==module && f.pages.find(pg=>pg.groupName==groupName && pg.pageMenu.find(pm=>pm.name==pageName || (pm.children && pm.children.find(ch=>ch.name==pageName)))));
     if(found){
       const page= found.pages.find(pg=>pg.groupName==groupName);
       if(page && page.pageMenu && page.pageMenu.find(f=>f.name==pageName || (f.children && f.children.find(ch=>ch.name==pageName)))){
          (page.pageMenu.find(f=>f.name==pageName|| (f.children && f.children.find(ch=>ch.name==pageName))) as any).active=true;
          result.menus=page.pageMenu;
          result.breadCrumbs=(page.pageMenu.find(f=>f.name==pageName || (f.children && f.children.find(ch=>ch.name==pageName))) as any).breadCrumbs;
          //need to include child 
          const pgMenu = page.pageMenu.find(f=>f.children && f.children.find(ch=>ch.name==pageName));
          if(pgMenu){
            const child= pgMenu.children?.find(f=>f.name==pageName) as any;
            const childBreadcrumb= new AppBreadCrumb();
            childBreadcrumb.link=child.link;
            childBreadcrumb.title=child.name;
            result.breadCrumbs.push(childBreadcrumb);
          }
          return result;
       }
     }

     return result;
    }
    
}


    
import { Redirect, Route } from "react-router-dom";
import { Component } from "react";
import Store, { AppKey } from "../../Global/Store";
import history from "../Components/BrowserHistory";
import { Util } from "../../Global/Util";

const RouteGuard = ({ component: Component, auth, ...rest }) => {
  Util.log("auth:" + auth);
  const isAuthenticated = new Store().get(AppKey.USER) ? true : false;
  const hasActiveUser = new Store().get(AppKey.HasActiveUser) ? true : false;
  Util.log("hasuser:" + hasActiveUser);
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated === true ? (
          props.location.pathname == "/" ? (
            <Redirect to="/home" />
          ) : (
            <Component {...props} />
          )
        ) : hasActiveUser ? (
          <Redirect to="/login" />
        ) : (
          // (
          // props.location.pathname == "/" ||
          // props.location.pathname == "/user-setup") && (
          <Redirect to="/user-setup" />
          // )
        )
      }
    />
  );
};

export default RouteGuard;

export enum RolePageAction {
  Tenant = "Tenant",
  Company = "Company",
  ChartOfAccount = "Chart of Accounts",
  TrialBalance = "Trial Balance",
  TransactionHistory = "Transaction History",
  TransactionGroup = "Transaction Group",
  PostingProfile = "Posting Profile",
  PeriodClosing = "Period Closing",
  OrgStructure = "Organizational Structure",
  Consolidation = "Consolidation",
  Dimension = "Dimension",
  ReportDesigner = "Report Designer",
  ReportViewer = "Report Viewer",
  AuditTrail = "Audit Trail",
  Asset = "Asset",
  AssetClass = "Asset Class",
  Tag = "Tag",
  Tax = "Tax",
  DimensionGroup = "Dimension Group",
  RecurringDepreciation = "Recurring Depreciation",
  Attribute = "Attribute",
  IdentityType = "Identity Type",
  AttributeSet = "Attribute Set",
  Identity = "Identity",
  IdentityGroup = "Identity Group",
  Address = "Address",
  ControlGLink = "Control GL Link",
  ModuleConfig = "Module Config",
  ModuleManagement = "Module Management",
  LedgerHeader = "Ledger Header",
  Product = "Product",
  ProductGroup = "Product Group",
  VendorPaymentSchedule = "Vendor Payment Schedule",
  VendorInvoice = "Vendor Invoice",
  AP = "Account Payable",
  AR = "Account Receivable",
  FA = "Fixed Asset",
  Admin = "Admin",
  UserProfile = "User Profile",
  VendorGroup = "Vendor Group",
  Accounts = "Accounts",
  VendorPaymentTerm = "Vendor Payment Term",
  VendorPayment = "Vendor Payment",
  Task = "Task",
  Workflow = "Workflow",
  WorkflowChart = "Workflow Chart",
  WorkflowTag = "Workflow Tag",
  PR = "Payroll",
  SalarySchedule = "Salary Schedule",
  SalaryScale = "Salary Scale",
  GradeLevel = "Grade Level",
  Step = "Step",
  Allowance = "Allowance",
  Deduction = "Deduction",
  PaymentDescription = "Payment Description",
  Department = "Department",
  StaffAllowance = "Staff Allowance",
  StaffDeduction = "Staff Deduction",
  StaffUnit = "Staff Unit",
  Staff = "Staff",
  Salary = "Salary",
  CM = "Cash Management",
  ServiceMonitor = "Service Monitor",
  CompanyConfig = "Company Configuration",
  DepreciationSchedule = "Depreciation Schedule",
  RevaluationSchedule = "Revaluation Schedule",
  DisposalSchedule = "Disposal Schedule",
  SplittingSchedule = "Splitting Schedule",
  EnhancementSchedule = "Enhancement Schedule",
  InsuranceCompany = "Insurance Company",
  MovementSchedule = "Movement Schedule",
  InsuranceSchedule = "Insurance Schedule",
  MaintenanceSchedule = "Maintenance Schedule",
  StockTaking = "Stock Taking",
  NumberSequence = "Number Sequence",
  FailedPayment = "Failed Payment",
  NumberSegment = "Number Segment",
  JournalTemplate = "Journal Template",
  Journal = "Journal",
  AdjustmentJournal = "Adjustment Journal",
  OpeningBalanceJournal = "Opening Balance Journal",
  RecurringJournal = "Recurring Journal",
  Subsidiary = "Subsidiary",
  Operation = "Operation",
  CustomerRecurringPayment = "Customer Recurring Payment",
  VendorRecurringPayment = "Vendor Recurring Payment",

  Bank = "Bank Account",
  BankGroup = "Bank Group",
  CashflowForecast = "Cashflow Forecast",
  FundTransfer = "Fund Transfer",
  BankReconciliation = "Bank Reconciliation",

  //Account Class
  AccountClass = "Account Class",
  SaveAccountClass = "Save Account Class",
  EditAccountClass = "Edit Account Class",
  DeleteAccountClass = "Delete Account Class",
  ExportAccountClass = "Export Account Class",
  ImportAccountClass = "Import Account Class",

  // Account Group
  AccountGroup = "Account Group",
  SaveAccountGroup = "Save Account Group",
  EditAccountGroup = "Edit Account Group",
  DeleteAccountGroup = "Delete Account Group",
  ExportAccountGroup = "Export Account Group",
  ImportAccountGroup = "Import Account Group",

  // Account Opearion  Account
  OperationAccount = "Operation Account",
  SaveOperationAccount = "Save Operation Account",
  EditOperationAccount = "Edit Operation Account",
  DeleteOperationAccount = "Delete Operation Account",
  AllocationRuleOperationAccount = "Allocation Rule",
  ExportOperationAccount = "Export Operation Account",
  ImportOperationAccount = "Import Operation Account",

  // Account Control  Account
  ControlAccount = "Control Account",
  SaveControlAccount = "Save Control Account",
  EditControlAccount = "Edit Control Account",
  DeleteControlAccount = "Delete Control Account",
  ExportControlAccount = "Export Control Account",
  ImportControlAccount = "Import Control Account",

  //Currency List
  CurrencyList = "Currency List",
  SaveCurrency = "Save Currency",
  EditCurrency = "Edit Currency",
  DeleteCurrency = "Delete Currency",
  ExportCurrency = "Export Currency",
  ImportCurrency = "Import Currency",

  //Exchange Rate
  ExchangeRate = "Exchange Rate",
  SaveExchangeRate = "Save Exchange Rate",
  EditExchangeRate = "Edit Exchange Rate",
  DeleteExchangeRate = "Delete Exchange Rate",
  ExportExchangeRate = "Export Exchange Rate",
  ImportExchangeRate = "Import Exchange Rate",

  // Inter Company
  InterCompanyJournalTemplate = "Inter Company Journal Template",
  InterCompanyJournal = "Inter Company Journal",
  SaveInterCompanyJournalTemplate = "Save Inter Company Journal Template",
  EditInterCompanyJournalTemplate = "Edit Inter Company Journal Template",
  DeleteInterCompanyJournalTemplate = "Delete Inter Company Journal Template",
  ExportInterCompanyJournalTemplate = "Export Inter Company Journal Template",
  ImportInterCompanyJournalTemplate = "Import Inter Company Journal Template",

  SaveInterCompanyJournal = "Save Inter Company Journal",
  EditInterCompanyJournal = "Edit Inter Company Journal",
  DeleteInterCompanyJournal = "Delete Inter Company Journal",
  ExportInterCompanyJournal = "Export Inter Company Journal",
  ImportInterCompanyJournal = "Import Inter Company Journal",

  //Fiscal year
  FiscalYear = "Fiscal Year",
  AccountingPeriod = "Accounting Period",
  SaveFiscalYear = "Save Fiscal Year",
  EditFiscalYear = "Edit Fiscal Year",
  DeleteFiscalYear = "Delete Fiscal Year",
  ExportFiscalYear = "Export Fiscal Year",
  ImportFiscalYear = "Import Fiscal Year",

  //Journal
  SaveJournal = "Save Journal",
  EditJournal = "Edit Journal",
  DeleteJournal = "Delete Journal",
  ExportJournal = "Export Journal",
  ImportJournal = "Import Journal",

  //Journal Template
  SaveJournalTemplate = "Save Journal Template",
  EditJournalTemplate = "Edit Journal Template",
  DeleteJournalTemplate = "Delete Journal Template",
  ExportJournalTemplate = "Export Journal Template",
  ImportJournalTemplate = "Import Journal Template",

  //Recurring Journal
  SaveRecurringJournal = "Save Recurring Journal",
  EditRecurringJournal = "Edit Recurring Journal",
  DeleteRecurringJournal = "Delete Recurring Journal",
  ExportRecurringJournal = "Export Recurring Journal",
  ImportRecurringJournal = "Import Recurring Journal",

  //Trial Balance
  GenarateTrialBalanceReport = "Genarate Report",
  ImportTrialBalanceReport = "Import Trial Balance Report",
  ExportTrialBalanceReport = "Export Trial Balance Report",

  //Posting Profile
  SavePostingProfile = "Save Posting Profile",
  EditPostingProfile = "Edit Posting Profile",
  DeletePostingProfile = "Delete Posting Profile",
  ExportPostingProfile = "Export Posting Profile",
  ImportPostingProfile = "Import Posting Profile",

  //License
  SaveLicense = "Save License",
  EditLicense = "Edit License",
  DeleteLicense = "Delete License",

  //Contract
  Contract = "Contract",
  SaveContract = "Save Contract",
  EditContract = "Edit Contract",
  DeleteContract = "Delete Contract",

  //Tag
  SaveTag = "Save Tag",
  EditTag = "Edit Tag",
  DeleteTag = "Delete Tag",

  //Workflow
  SaveWorkflow = "Save Workflow",
  EditWorkflow = "Edit Workflow",
  DeleteWorkflow = "Delete Workflow",

  //Message Config
  SaveMessageConfig = "Save Message Config",
  EditMessageConfig = "Edit Message Config",
  DeleteMessageConfig = "Delete Message Config",

  //Actor
  SaveActor = "Save Actor",

  //Organizational Structure
  SaveTenant = "Save Tenant",
  EditTenant = "Edit Tenant",
  DeleteTenant = "Delete Tenant",

  SaveCompany = "Save Company",
  EditCompany = "Edit Company",
  DeleteCompany = "Delete Company",

  //Consolidation
  SaveConsolidation = "Save Consolidation",
  EditConsolidation = "Edit Consolidation",
  DeleteConsolidation = "Delete Consolidation",
  ExportConsolidation = "Export Consolidation",
  ImportConsolidation = "Import Consolidation",

  //Company Config
  SaveCompanyConfig = "Save Company Config",
  EditCompanyConfig = "Edit Company Config",
  DeleteCompanyConfig = "Delete Company Config",
  ExportCompanyConfig = "Export Company Config",
  ImportCompanyConfig = "Import Company Config",

  //Number Sequence
  SaveNumberSequence = "Save Company Config",
  EditNumberSequence = "Edit Number Sequence",
  DeleteNumberSequence = "Delete Number Sequence",
  ExportNumberSequence = "Export NumberSequence",
  ImportNumberSequence = "Import NumberSequence",

  //Dimension
  SaveDimension = "Save Dimension",
  EditDimension = "Edit Dimension",
  DeleteDimension = "Delete Dimension",
  ExportDimension = "Export Dimension",
  ImportDimension = "Import Dimension",

  //Role & Permission
  SaveRole = "Save Role",
  EditRole = "Edit Role",
  DeleteRole = "Delete Role",
  SaveUser = "Save User",
  EditUser = "Edit User",
  DeleteUser = "Delete User",

  //Report Designer
  SaveReport = "Save Report",
  EditReport = "Edit Report",
  DeleteReport = "Delete Report",
  SaveReportSchedule = "Save Report Schedule",
  EditReportSchedule = "Edit Report Schedule",
  DeleteReportSchedule = "Delete Report Schedule",
  WorkflowActor = "Workflow Actor",
  Vendor = "Vendor",
  RolePermission = "Role & Permission",
  CustomerPaymentSchedule = "Customer Payment Schedule",
  CustomerInvoice = "Customer Invoice",
  Customer = "Customer",
  GL = "General Ledger",
  MessageConfig = "Message Config",
  License = "License",
  LicenseManager = "License Manager",
  CustomerPayment = "Customer Payment",
  CustomerPaymentTerm = "Customer PaymentTerm",
  CustomerGroup = "Customer Group",

  // Inventory
  INV = "Inventory",
  WarehouseManagement = "Warehouse Management",
  InventoryManagement = "Inventory Management",
  Site = "Site",
  Warehouse = "Warehouse",
  Location = "Location",

  Stock = "Stock",
  StockMovement = "Stock Movement",
  StockTransfer = "Stock Transfer",
  VendorStatement = "Vendor Statement",
  WorkflowTask = "Workflow Task"
}

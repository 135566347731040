import React, { Component } from "react";
import { UserPageHTML } from "./UserPageHTML";
import Store, { AppKey } from "../../../Global/Store";
import { Md5Hash } from "../../../Global/HashString";
import { AuthUser } from "../../../Models/AuthUser";
import { OrganisationalService } from "../../../HttpServices/GL/OrganisationalService";
import { Company } from "../../../Models/Company";
import { Status } from "../../../Models/Status";
import { AuthService } from "../../../HttpServices/Auth/AuthService";
import { PagedResult } from "../../../Models/PagedResult";
import { ErrorResponse } from "../../../Models/ErrorResponse";
import { UserRole } from "../../../Models/UserRole";
import { EntityState } from "../../../Models/EntityState";
import MainPage from "../../Components/MainPage/MainPage";
import history from "../../Components/BrowserHistory";
import { TenantRequest } from "../../../Models/TenantRequest";
import { Util } from "../../../Global/Util";

interface Props {
  PageName?: string;
}
export interface PageState {
  loading: boolean;
  activeUser: AuthUser;
  currentLoggedInUser?: AuthUser;
  companies: {}[];
  roleList: {}[];
  status: {}[];
  mainPageRef: any;
  dlgOpen: boolean;
  eulaContent: string;
  tenant?: TenantRequest;
}

export default class UserPage extends Component<Props, PageState> {
  store = new Store();
  constructor(props: Props) {
    super(props);
    this.state = {
      eulaContent: "",
      dlgOpen: true,
      mainPageRef: React.createRef(),
      status: [],
      companies: [],
      roleList: [],
      loading: false,
      activeUser: new AuthUser(),
      currentLoggedInUser: this.store.get(AppKey.USER)
        ? (this.store.get(AppKey.USER) as AuthUser)
        : undefined,
    };
  }
  toggle(open: boolean) {
    this.setState({ dlgOpen: open });
  }
  showNotification(isSuccess: boolean, res?: ErrorResponse) {
    if ((this.state.mainPageRef.current as MainPage)) {
      (this.state.mainPageRef.current as MainPage).toggleNotif(
        true,
        isSuccess,
        res ? res.message : ""
      );
    }
  }
  setStatus() {
    var statusEnum: {}[] = [];
    for (let key in Status) {
      if (isNaN(Number(key))) {
        statusEnum.push({ key: key, value: parseInt(Status[key]), text: key });
      }
    }
    this.setState({ status: statusEnum });
  }
  async loadTenantRequest() {
    this.setState({ loading: true });
    const result = await new AuthService().getTenantRequestByURL();
    if (result instanceof ErrorResponse) {
      this.showNotification(false, result);
    } else {
      Util.log(result);
      result as TenantRequest;
      this.setState({
        dlgOpen:
          result && !(result as TenantRequest).isLicenseManager ? true : false,
        eulaContent:
          result && !(result as TenantRequest).isLicenseManager
            ? (result as TenantRequest).globalAgreement
            : "",
        tenant: result as TenantRequest,
      });
    }
    this.setState({ loading: false });
  }
  async loadRoles() {
    const result = await new AuthService().getRoles();
    if (result instanceof ErrorResponse) {
      this.showNotification(false, result);
    } else {
      (result as PagedResult<UserRole[]>).result?.forEach((r) => {
        r.state = EntityState.Unchanged;
      });
      const roleList = (result as PagedResult<UserRole[]>).result?.map((r) => {
        return { key: r.id, value: r.id, text: r.name };
      });
      this.setState({
        roleList: roleList as {}[],
      });
    }
  }
  async loadCompanies() {
    const result = await new OrganisationalService().getCompanies(
      this.state.currentLoggedInUser?.tenant
    );
    if (result instanceof ErrorResponse) {
      this.showNotification(false, result);
    } else {
      const companies = (result as PagedResult<Company[]>).result as Company[];
      const compData = companies.map((m) => {
        return { key: m.code, text: m.description, value: m.code };
      });
      this.setState({ companies: compData });
    }
  }
  handleChange = (event: any, na: any = {}) => {
    var name = event.target.name ? event.target.name : na.name;
    const value = event.target?.value ? event.target.value : na.value;
    this.setState((prevState) => ({
      ...prevState,
      activeUser: {
        ...(prevState.activeUser as AuthUser),
        [name]: value,
      },
    }));
  };
  hashPsw() {
    var hash = Md5Hash("staging123");
    Util.log(hash);
  }
  async componentDidMount() {
    if (this.state.currentLoggedInUser) {
      this.loadRoles();
      this.loadCompanies();
      this.setStatus();
    } else {
      await this.loadTenantRequest();
    }
    this.hashPsw();
  }
  validateUserSave() {
    if (!this.state.activeUser.mailAddress) {
      this.showNotification(
        false,
        new ErrorResponse("Please supply valid email address")
      );
      return false;
    }
    if (!this.state.activeUser.name) {
      this.showNotification(false, new ErrorResponse("Please supply name"));
      return false;
    }
    if (!this.state.activeUser.password) {
      this.showNotification(false, new ErrorResponse("Please supply password"));
      return false;
    }
    if (!this.state.activeUser.confirmPassword) {
      this.showNotification(
        false,
        new ErrorResponse("Please supply confirm password")
      );
      return false;
    }
    if (
      this.state.activeUser.confirmPassword != this.state.activeUser.password
    ) {
      this.showNotification(
        false,
        new ErrorResponse("Password and confirm password must be the same")
      );
      return false;
    }
    return true;
  }
  async saveUser() {
    // this.store.set(AppKey.HasActiveUser, "1");
    // history.replace('/login')
    // window.location.reload();
    const { currentLoggedInUser } = this.state;
    if (this.validateUserSave()) {
      const userToSave = { ...this.state.activeUser } as AuthUser;
      userToSave.password =
        userToSave.password && userToSave.state == EntityState.New
          ? Md5Hash(userToSave.password)
          : userToSave.password;
      userToSave.state =
        userToSave.state == EntityState.Unchanged
          ? EntityState.Changed
          : EntityState.New;
      userToSave.key = "";
      userToSave.value = "";
      Util.log(userToSave);
      this.setState({ loading: true });
      const result = currentLoggedInUser
        ? await new AuthService().saveUserFirstTime(userToSave)
        : await new AuthService().saveUserFirstTime(userToSave);
      if (result instanceof ErrorResponse) {
        this.showNotification(false, result);
      } else {
        userToSave.state = EntityState.Unchanged;
        if (currentLoggedInUser) {
          this.showNotification(true);
        } else {
          this.store.set(AppKey.HasActiveUser, "1");
          history.push("/login");
          window.location.reload();
        }
      }
      this.setState({ loading: false });
    }
  }
  render() {
    return UserPageHTML(this);
  }
}
